import {Select} from "@mantine/core";
import "./CoursesFilter.css";
import {useAppContext} from "../../../context/AppState/AppContext";
import {useEffect, useState} from "react";

const SPECIALTIES = [
    'Medicina Felina',
    'Dermatología',
    'Diagnóstico por Imágenes-Ecografía',
    'Diagnóstico por Imágenes-Radiología',
    'Terapias Fisicas y Rehabilitacion',
    'Cirugía de tejidos blandos',
    'Traumatología',
    'Cardiologia',
    'Reproducción y neonatología',
    'Laboratorio y análisis clínicos',
    'Toxicología',
    'Medicina Interna',
    'Anestesiología',
    'Urología',
    'Citología',
    'Gastroenterología'
]

const ACADEMIC_UNITS = [
    'POSGRADO',
    'CURSO',
    'DIPLOMADO',
    'MASTERCLASS',
    'WEBINAR',
    'TALLER'
]

const MONTHS = [
    'ENERO',
    'FEBRERO',
    'MARZO',
    'ABRIL',
    'MAYO',
    'JUNIO',
    'JULIO',
    'AGOSTO',
    'SEPTIEMBRE',
    'OCTUBRE',
    'NOVIEMBRE',
    'DICIEMBRE'
]

const CoursesFilter = (props) => {
    const {
        specialtyFilter,
        setSpecialtyFilter,
        academicUnitFilter,
        setAcademicUnitFilter,
        monthFilter,
        setMonthFilter
    } = props;
    const {specialtiesInfo} = useAppContext();
    const [specialtiesOptions, setSpecialtiesOptions] = useState(SPECIALTIES);

    useEffect(() => {
        if (specialtiesInfo?.data) {
            setSpecialtiesOptions(specialtiesInfo?.data.map(specialty => specialty.name.toUpperCase()))
        }
    }, [specialtiesInfo]);

    return (
        <div className="coursesFilter-father-div">
            <div className="coursesFilter-filter-div-container">
                <span className="coursesFilter-span-styling">Especialidad</span>
                <Select
                    size="md"
                    radius="md"
                    value={specialtyFilter} onChange={setSpecialtyFilter}
                    placeholder={specialtiesOptions[0]}
                    clearable
                    searchable
                    nothingFoundMessage="Sin resultados para tu búsqueda"
                    data={specialtiesOptions}
                    classNames={{root: "courserFilter-select-wrapper-customization"}}
                />
                <span className="coursesFilter-second-span-styling">
                    Tipo de Curso
                </span>
                <Select
                    size="md"
                    radius="md"
                    value={academicUnitFilter} onChange={setAcademicUnitFilter}
                    placeholder={ACADEMIC_UNITS[0]}
                    clearable
                    searchable
                    nothingFoundMessage="Sin resultados para tu búsqueda"
                    data={ACADEMIC_UNITS}
                    classNames={{root: "courserFilter-select-wrapper-customization"}}
                />
                <span className="coursesFilter-second-span-styling">
                    Mes de inicio
                </span>
                <Select
                    size="md"
                    radius="md"
                    value={monthFilter} onChange={setMonthFilter}
                    placeholder={MONTHS[0]}
                    clearable
                    searchable
                    nothingFoundMessage="Sin resultados para tu búsqueda"
                    data={MONTHS}
                    classNames={{root: "courserFilter-select-wrapper-customization"}}
                />
            </div>
        </div>
    );
};
export default CoursesFilter;
