import NavLink from '../NavLink/NavLink'
import MegaMenuContainer from '../MegaMenu/MegaMenuContainer/MegaMenuContainer'
import { Button } from '@mantine/core'
import MegaMenuSection from '../MegaMenu/MegaMenuSection/MegaMenuSection'
import { useHeaderContext } from '../../../context/Header/HeaderContext'
import { useAppContext } from '../../../context/AppState/AppContext'
import { Link } from 'react-router-dom'

export default function HeaderNavList() {
   const { isMobile } = useAppContext()
   const { toggleBurger, onMouseEnterMenu, onMouseLeaveMenu, onClickMegaMenu } = useHeaderContext()

   return (
      <ul className={`navbar-navlinks-container ${toggleBurger ? 'navbar-toggle-active' : 'navbar-toggle-inactive'}`}>
         < NavLink text='Inicio' to='/' />
         < NavLink text='Institucional' to='/institucional' />

         {isMobile 
            ? <NavLink text='Oferta académica' to='/cursos' /> 
            : <NavLink
               text='Oferta académica'
               onMouseEnter={onMouseEnterMenu}
               onMouseLeave={onMouseLeaveMenu}
               onClick={onClickMegaMenu}
               withMenu
            >
               <MegaMenuContainer  > 
                  < MegaMenuSection />
               </MegaMenuContainer>
            </NavLink>
         }

         < NavLink text='Contacto' to='/contacto' />

         <Button component={Link} to='/campus' fw='normal' color='secondaryColor' radius='normal'>
            CAMPUS
         </Button>
      </ul>
   )
}
