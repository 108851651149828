import React, {useEffect, useState} from "react";
import {Button} from "@mantine/core";
import "./LandingHero.css";
import {Typewriter} from "react-simple-typewriter";
import {useNavigate} from "react-router-dom";
import Hero1 from "../../../assets/images/hero1.png";
import Hero2 from "../../../assets/images/hero2.png";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

function LandingHero() {
    const navigate = useNavigate();
    const [shake, setShake] = useState(false);
    const {width} = useWindowDimensions();
    const isMediumScreen = width <= 1200;
    const isMobile = width <= 768;

    const getButtonSize = () => {
        if (isMobile) {
            return "sm";
        } else if (isMediumScreen) {
            return "lg";
        } else {
            return "xl";
        }
    }


    useEffect(() => {
        const intervalId = setInterval(() => {
            setShake(true);

            setTimeout(() => {
                setShake(false);
            }, 2000);
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);
    return (
        <div className="landingHero__container">
            <div className="landingHero__column landingHero__column--left">
                <h1 className="landingHero__title">
                    DONDE EXPERTOS FORMAN Y <br/>
                    <span className="landingHero__gradient">
            <Typewriter
                words={["COMPARTEN", "CONSTRUYEN", "COLABORAN", "TRANSFORMAN"]}
                delaySpeed={1500}
                cursor={true}
                cursorStyle="|"
                cursorBlinking={true}
                loop={false}
            />
          </span>
                </h1>
                <div className="landingHero__block">
                    <p className="landingHero__text">
                        Potenciá tus conocimientos y habilidades bajo la mentoría de
                        expertos reconocidos internacionalmente para marcar la diferencia en
                        la comunidad veterinaria. Juntos, redefiniendo el futuro de la
                        profesión.
                    </p>
                </div>
                <Button
                    className={`landingHero__button ${shake ? 'shake' : ''}`}
                    variant="contained"
                    bg="var(--color-tertiary)"
                    size={getButtonSize()}
                    fw="normal"
                    onClick={() => navigate(`/cursos`)}
                >
                    EXPLORA NUESTROS CURSOS
                </Button>
            </div>
            <div className="landingHero__column landingHero__column--right">
                <img className="landingHero__image-1" src={Hero1} alt="hero_cat"/>
                <img className="landingHero__image-2" src={Hero2} alt="hero_dog"/>
            </div>
        </div>
    );
}

export default LandingHero;
