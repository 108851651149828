import React from 'react';
import './ContactCardIcon.css';

function ContactCardIcon({ icon: Icon, title, description, info, onClick = null, ...others }) {
    return (
        <div className="contactCardIcon-wrapper" {...others}>
            <Icon className="contactCardIcon-icon" />

            <div className="contactCardIcon-content">
                <span className="contactCardIcon-title">
                    {title}
                </span>

                {description.map((item, index) => (
                    <span
                        key={index}
                        className="contactCardIcon-text"
                        onClick={onClick ? () => onClick(item) : null}
                        style={onClick ? {cursor: 'pointer'} : {}}
                    >
                        {item}
                    </span>
                ))}
                <span className='contactCardIcon-info'>
                    {info}
                </span>
            </div>
        </div>
    );
}

export default ContactCardIcon;