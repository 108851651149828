import "./CourseDetails.css";
import MainLayout from "../layouts/MainLayout/MainLayout";
import CourseRequirements from "../features/Course/CourseRequirements/CourseRequirements";
import CourseCertificationContainer from "../features/Course/CourseCertification/CourseCertificationContainer";
import CourseBenefits from "../features/Course/CourseBenefits/CourseBenefits";
import CourseTab from "../features/Course/CourseTab/CourseTab";
import CourseHero from "../features/Course/CourseHero/CourseHero";
import {useEffect, useState} from "react";
import CourseDetailsCard from "../features/Course/CourseDetailsCard/CourseDetailsCard";
import {IconReport, IconClock, IconCalendar} from "@tabler/icons-react";
import {Button, Skeleton} from "@mantine/core";
import axios from "axios";
import DecorativeCircle from "../components/DecorativeCircle/DecorativeCircle";
import TinyDecorativeCircle from "../components/TinyDecorativeCircle/TinyDecorativeCircle";
import {useNavigate, useParams} from 'react-router-dom'
import {useAppContext} from "../context/AppState/AppContext";
import {calculatePrice} from "../utils/calculatePrice";

const featuresBuilder = (_cardInfo) => {
    return [
        {
            icon: <IconReport className="cardFeature__icon"/>,
            text: "Duración del curso",
            description: `${_cardInfo.classQuantity} Clases`
        },
        {
            icon: <IconClock className="cardFeature__icon"/>,
            text: "Horario",
            description: `${_cardInfo.classHour} hs. (UTC-3)`
        },
        {
            icon: <IconCalendar className="cardFeature__icon"/>,
            text: "Días",
            description: `${_cardInfo.classDays}`
        },
    ]
}

export default function CourseDetails() {
    const {setPaymentData, isUSD, isMobile, officialUSDValue} = useAppContext();
    const [academicUnitInfo, setAcademicUnitInfo] = useState(null);
    const {id} = useParams()
    const navigate = useNavigate();

    const handleInscription = () => {
        if (!isOpen) return
        setPaymentData({
            name: academicUnitInfo.name,
            id: academicUnitInfo.id,
            price,
            image: academicUnitInfo.image,
            subscriptionPlans: academicUnitInfo.subscriptionPlans, // Only PayPal
        });
        navigate("/pago")
    }

    useEffect(() => {
        const url = process.env.REACT_APP_BACKEND_SERVER + `/info/academicUnits/${id}`;
        axios.get(url)
            .then(response => {
                setAcademicUnitInfo(response.data)
            })
            .catch(err => console.log(err))
    }, [id])

    if (!academicUnitInfo) return <MainLayout><Skeleton height={400} radius="sm"/></MainLayout>

    const OPEN_AU_STRING = 'Open'
    const price = calculatePrice(academicUnitInfo.price, officialUSDValue, isUSD, academicUnitInfo.type);
    const isOpen = academicUnitInfo.state === OPEN_AU_STRING

    return (
        <MainLayout>
            <div className="course__layout">
                <section className="course__hero" style={{position: "relative"}}>
                    <CourseHero
                        isOpen={isOpen}
                        title={academicUnitInfo.name}
                        description={academicUnitInfo.description}
                        imageUrl={academicUnitInfo.heroImage}
                    />
                    <DecorativeCircle size={100} top={-20} left={0} opacity={0.5} shadow={1}/>
                    <DecorativeCircle size={150} bottom={-100} left={300} opacity={0.2} shadow={1} hideInMobile/>
                    <DecorativeCircle size={50} top={-20} left={600} opacity={0.2} shadow={4} hideInMobile/>
                    <DecorativeCircle size={100} top={-50} right={0} opacity={0.2} shadow={2}/>
                    <TinyDecorativeCircle size={12} top={50} right={300} opacity={1} color={1}/>
                    <TinyDecorativeCircle size={12} top={300} left={70} opacity={0.75} color={1}/>
                    <TinyDecorativeCircle size={32} top={80} left={500} opacity={1} color={3} hideInMobile/>
                </section>


                <aside className="course__aside">
                    <CourseDetailsCard
                        price={price}
                        features={featuresBuilder(academicUnitInfo.cardInfo)}
                        handleInscription={handleInscription}
                        isOpen={isOpen}
                        // This is equivalent to state sent above on navigate(), beware if you change it
                        courseData={{
                            name: academicUnitInfo.name,
                            id: academicUnitInfo.id,
                        }}
                    />
                </aside>

                <div className="course__main">
                    <section className='course__section'>
                        <CourseTab
                            modules={academicUnitInfo.modules}
                            staff={academicUnitInfo.staff}
                            reviews={academicUnitInfo.reviews}
                        />
                    </section>

                    <section className="course__section--noPadding">
                        <CourseRequirements text={academicUnitInfo.whoIsThisFor}/>
                    </section>

                    <section className="course__section">
                        <CourseCertificationContainer certificate={academicUnitInfo.certificate}/>
                    </section>

                    <section className="course__section">
                        <CourseBenefits/>
                    </section>

                    {!isOpen && !isMobile &&
                    <section className="course__section">
                        <span className="sectionTitle-upText">¡Inscripciones cerradas!</span>
                    </section>}
                </div>
            </div>

            <div className="course__buttons">
                <Button className="courseDetailsCard__button" fw='normal' color='secondaryColor' disabled={!isOpen}
                        fullWidth onClick={() => handleInscription()}>
                    {isOpen ? 'Inscribirse' : 'Inscripciones cerradas'}
                </Button>
                <Button className="courseDetailsCard__button" fw='normal' variant="default" fullWidth
                        onClick={() => navigate("/contacto", {
                            // This is equivalent to courseData, beware if you change it
                            state: {
                                name: academicUnitInfo.name,
                                id: academicUnitInfo.id,
                            }
                        })}>
                    Consultar un asesor
                </Button>
            </div>
        </MainLayout>
    )
}
