import CardInfoRow from "../../../../components/CardInfo/CardInfoRow/CardInfoRow";
import "./CourseProfessorData.css";

const CourseProfessorData = ({data}) => {
    return (
        <div>
            {data?.about?.personalItems && (
                <div className="courseProfessorInfo-second-section-div">
                    <div className="courseProfessorInfo-second-section-div-content-container">
                        <ul className="courseProfessorInfo-second-section-ul-list">
                            {data.about.personalItems.map((item, i) => (
                                <li key={i}>
                                    <p className="courseProfessorInfo-second-section-ul-p">
                                        <span>{item.item}</span>: {item.value}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}

            {data?.about?.description && (
                <div className="courseProfessorInfo-third-section-div">
                    <div className="courseProfessorInfo-third-section-div-content-container">
                        <h5 className="courseProfessorInfo-third-section-title-h5">
                            Sobre mí
                        </h5>
                        <p className="courseProfessorInfo-second-section-ul-p">
                            {data.about.description}
                        </p>
                    </div>
                </div>
            )}

            {/*  <div className="courseProfessorInfo-fourth-section-div">
        <h5 className="courseProfessorInfo-fourth-section-title-h5">
          Otros cursos del docente
        </h5>
        {data.courses.map((extraCourse, index) => (
          <CardInfoRow
            key={index}
            classes={extraCourse.classes}
            price={extraCourse.price}
            date={extraCourse.date}
            imgUrl={extraCourse.imgUrl}
            imgAlt={extraCourse.imgAlt}
            title={extraCourse.name}
            tag={extraCourse.name}
            buttonText={extraCourse.buttonText}
            buttonColor={extraCourse.buttonColor}
            buttonVariant={extraCourse.buttonVariant}
          />
        ))}
      </div> */}
        </div>
    );
};

export default CourseProfessorData;
