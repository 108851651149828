const generateRandomCode = (_digits) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'; // Caracteres disponibles para el código
    let code = '';

    for (let i = 0; i < _digits; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        code += chars.charAt(randomIndex);
    }

    return code;
}

export { generateRandomCode }