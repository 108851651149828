import CourseProfessorAvatar from "../CourseProfessorAvatar/CourseProfessorAvatar";
import CourseProfessorData from "../CourseProfessorData/CourseProfessorData";
import "./CourseProfessorInfo.css";
import {useState} from "react";
import {Carousel} from "@mantine/carousel";
import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import {useAppContext} from "../../../../context/AppState/AppContext";

const ROLES_DICT = {
    "teachers": "Docente",
    "director": "Director",
    "codirector": "Codirector",
    "coordinator": "Coordinador académico",
}

const CourseProfessorInfo = ({staff}) => {
    const [selectedProfessor, setSelectedProfessor] = useState(0);
    const {isMobile} = useAppContext();

    if (!staff) {
        return (
            <div className="courseProfessorInfo-note">
                Sin información disponible
            </div>
        );
    }

    const staffArray = Object.keys(staff).flatMap((key) => {
        if (staff[key] !== null) {
            if (key === 'teachers' || key === 'director') {
                return staff[key].map((teacher) => ({
                    ...teacher,
                    role: ROLES_DICT[key]
                }));
            } else {
                return {
                    ...staff[key],
                    role: ROLES_DICT[key]
                };
            }
        }
        return []; // If value it's null, return an empty array to avoid key
    });

    return (
        <>
            <div className="courseProfessorInfo-carousel-div-container">
                    <Splide
                    options={{
                        rewind: true,
                        width: '100%',
                        gap: '1rem',
                        perPage: 3,
                        breakpoints: {
                            1200: {
                                perPage: 2,
                            },
                            801: {
                                perPage: 1,
                            }
                      }
                    }}
                    onActive={(e) => {
                        setSelectedProfessor(e.index);
                    }}
                >
                    {staffArray.map((professor, index) => (
                        <SplideSlide key={index} className="courseProfessorInfo-mantine-carousel-slide-styling">
                            <CourseProfessorAvatar
                                professor={professor}
                                index={index}
                                setSelectedProfessor={setSelectedProfessor}
                                selectedProfessor={selectedProfessor}
                            />
                        </SplideSlide>
                    ))}
                    </Splide>
            </div>
            <CourseProfessorData data={staffArray[selectedProfessor]}/>
        </>
    );
};

export default CourseProfessorInfo;
