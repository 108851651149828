import { Button } from '@mantine/core'
import './CardPhotoBg.css'

export default function CardPhotoBg({ imgUrl, title, tags = ['gratis'], description, buttonAction = null }) {
   
   const defaultImg = 'https://img.freepik.com/foto-gratis/cerrar-hombre-sonriente-sosteniendo-pata-perro_23-2149304311.jpg?w=1380&t=st=1701698963~exp=1701699563~hmac=5801f81e5375469873055d72cbfc4e126bd447d0fe99d7facf146380f9cce117'

   return (
      <article className='cardPhotoBg-container' style={{ backgroundImage: `url(${imgUrl ?? defaultImg})` }}>

         {tags &&
            <div className='cardPhotoBg-tags-container'>
               {tags.map(item => (
                  <span key={item} className='cardPhotoBg-tag'>{item}</span>
               ))}
            </div>
         }

         <div className='cardPhotoBg-content'>
            <div className='cardPhotoBg-rowGroup'>
               <h3 className='cardPhotoBg-title'>{title}</h3>
            </div>

            {description &&
               <div className='cardPhotoBg-rowGroup'>
                  <p className='cardPhotoBg-description'>{description}</p>
               </div>
            }

            <div className='cardPhotoBg-rowGroup'>
               <Button onClick={buttonAction} >Ver más</Button>
            </div>
         </div>
      </article>
   )
}
