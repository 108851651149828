import React, {useState} from 'react';
import './CreateModuleForm.css';
import {useForm} from "@mantine/form";
import {TextInput, Button, Grid, Textarea} from "@mantine/core";
import axios from "axios";
import showNotification from "../../../utils/showNotification";

function CreateModuleForm({updateData, setUpdateData, setOpen}) {
    const [loading, setLoading] = useState(false);
    const form = useForm({
        initialValues: {
            name: "",
            realName: "",
            description: "",
            academicUnits: [],
            lessons: []
        },
        validate: {},
    });
    const handleSubmit = async (values) => {
        console.log(values)
        setLoading(true)

        const data = {
            data: {...values}
        }
        axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/admin/modules`, data)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setUpdateData(!updateData)
                    setOpen(false)
                } else {
                    showNotification({
                        color: "red",
                        status: "error",
                        title: "Error",
                        message: `Error al crear el módulo`,
                    });
                }
            })
            .catch((err) => {
                const error = err.message || err.response.data.message || err || "Error al crear el módulo";
                showNotification({
                    color: "red",
                    status: "error",
                    title: "Error",
                    message: `Error al crear el módulo: ${error}`,
                });
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className="createModule__container">
            <h2 className="createModule__title">Crear módulo</h2>
            <form
                onSubmit={form.onSubmit((values) => handleSubmit(values))}
                className="createModule__form"
            >
                <Grid style={{width: "100%"}}>
                    <Grid.Col span={12}>
                        <TextInput
                            {...form.getInputProps('realName')}
                            withAsterisk
                            label="Nombre real del módulo"
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            {...form.getInputProps('name')}
                            withAsterisk
                            label="Nombre visible del módulo"
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Textarea
                            {...form.getInputProps('description')}
                            withAsterisk
                            label="Descripción del módulo"
                            autosize
                            minRows={3}
                        />
                    </Grid.Col>
                </Grid>
                <Button type="submit" className="createModule__button" loading={loading}>Crear módulo</Button>
            </form>
        </div>
    );
}

export default CreateModuleForm;