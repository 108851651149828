import PaymentPage from "../../features/PaymentCheckout/PaymentPage/PaymentPage";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import './PaymentCheckout.css';

const PaymentCheckout = () => {
  return (
    <MainLayout overflow="hidden">
        <section className="paymentSuccess__section">
            <PaymentPage/>
        </section>

    </MainLayout>
  )
}
export default PaymentCheckout