import './Header.css'
import { Burger } from '@mantine/core';
import HeaderLogo from './HeaderLogo/HeaderLogo';
import { useHeaderContext } from '../../context/Header/HeaderContext';
import HeaderNavList from './HeaderNavList/HeaderNavList';

export default function Header() {
   const { isMegaMenu, isScrolling, toggleBurger, setToggleBurger } = useHeaderContext()
   
   return (
      <header className={isMegaMenu || isScrolling || toggleBurger ? 'navbar active' : 'navbar'}>

         <nav className='nav-container'>
            < HeaderLogo />

            < HeaderNavList />

             {/*TODO: Review after merge issue*/}
             {/*<Button fw='normal' color='secondaryColor.3' radius='normal'>*/}
             {/*    CAMPUS*/}
             {/*</Button>*/}
            <Burger className='burger-btn' opened={toggleBurger} onClick={() => { setToggleBurger(!toggleBurger) }} aria-label="Toggle navigation" />
         </nav>

      </header>
   )
}