export const getRandomFromArray = (array) => {
   const randomIndex = Math.floor(Math.random() * array.length)
   const random = array[randomIndex]
   return random
}

// example
// const anArrayToCompare = ['random value 1', 'random value 2', 'random value 3']
// const randomValue = getRandomImageUrl(anArrayToCompare)}

