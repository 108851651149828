import React from 'react';
import './CreateSubscriptionPlanForm.css';
import {useForm} from "@mantine/form";
import {Button, Grid, NumberInput} from "@mantine/core";

function CreateSubscriptionPlanForm({setOpen, formHandler}) {
    const form = useForm({
        initialValues: {
            total_cycles: 0,
            amount_per_cycle: 0,
            index: 1,
        },
        validate: {},
    });
    const handleSubmit = async (values) => {
        const newSubscriptionPlan = {
            total_cycles: values.total_cycles,
            amount_per_cycle: values.amount_per_cycle,
            index: formHandler.values.subscriptionPlans.length + 1,
        }
        formHandler.setFieldValue("subscriptionPlans", [...formHandler.values.subscriptionPlans, newSubscriptionPlan])

        setOpen(false)
    }


    return (
        <div className="createSubscription__container">
            <h2 className="createSubscription__title">Crear plan de pago en cuotas (PAYPAL)</h2>
            <form
                onSubmit={form.onSubmit((values) => handleSubmit(values))}
                className="createSubscription__form"
            >
                <Grid style={{width: "100%"}}>
                    <Grid.Col span={12}>
                        <NumberInput
                            label="Cantidad de cuotas"
                            {...form.getInputProps('total_cycles')}
                            min={1}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <NumberInput
                            label="Monto de cuota (USD)"
                            {...form.getInputProps('amount_per_cycle')}
                            min={1}
                        />
                    </Grid.Col>
                </Grid>
                <Button type="submit" className="createSubscription__button">
                    Crear plan de pago en cuotas
                </Button>
            </form>
        </div>
    );
}

export default CreateSubscriptionPlanForm;