import MainLayout from "../../layouts/MainLayout/MainLayout";
import "./Registry.css";
import PaintingDog from "../../assets/Campus/painter.gif";
import {Button} from "@mantine/core";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {useNavigate} from "react-router";
import {useEffect} from "react";

const Registry = () => {
    const {width} = useWindowDimensions();
    const isMobile = width <= 768;
    const navigate = useNavigate();

    useEffect(() => {
        navigate(`//campus.forvet.org`);
    }, []);

    return (
        <MainLayout overflow="hidden">
            <div className="registry-column-div">
                <div className="registry-divContainer">
                    <div className="registry-content-div">
                        <h2 className="registry-content-title">
                            Estamos creando algo especial que pronto estará listo.
                        </h2>
                    </div>

                    {!isMobile && (
                        <div className="registry-image-div">
                            <img
                                src={PaintingDog}
                                alt="Painting Dog Working"
                                className="registry-image"
                            />
                        </div>
                    )}
                </div>
                <div className="registry-button-div">
                    <Button onClick={() => navigate(`//campus.forvet.org`)}>Ir al campus</Button>
                </div>
            </div>
        </MainLayout>
    );
};

export default Registry;
