import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import {MantineProvider} from '@mantine/core';
import '@mantine/core/styles.css';
import {Notifications} from '@mantine/notifications';
import {theme} from './theme/mantine';
import './index.css';
import '@mantine/notifications/styles.css';
import MetaPixel from "./components/MetaPixel/MetaPixel";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <MantineProvider theme={theme} defaultColorScheme="dark">
        <Notifications zIndex={1000}/>
        <MetaPixel/>
        <App/>
    </MantineProvider>
);