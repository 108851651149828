import {Tabs} from "@mantine/core";
import "./CourseTab.css";
import CourseScheduleAccordion from "../CourseScheduleAccordion/CourseScheduleAccordion";
import CourseReviewsCard from "../CourseReviews/CourseReviewsCard/CourseReviewsCard";
import CourseProfessorInfo from "../CourseProfessorInfo/CourseProfessorInfo/CourseProfessorInfo";

const CourseTab = ({modules, staff, reviews}) => {


    return (
        <div className="courseTab-tabs-div-container">
            <Tabs
                defaultValue="programa"
                classNames={{
                    root: "courseTab-root-tabs",
                    tabLabel: "courseTab-tabLabel-tabs",
                }}
            >
                <Tabs.List grow>
                    <Tabs.Tab value="programa">PROGRAMA</Tabs.Tab>
                    <Tabs.Tab value="profesores">EQUIPO</Tabs.Tab>
                    <Tabs.Tab value="valoraciones">VALORACIONES</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="programa">
                    <CourseScheduleAccordion modules={modules}/>
                </Tabs.Panel>

                <Tabs.Panel value="profesores">
                    <CourseProfessorInfo staff={staff}/>
                </Tabs.Panel>
                <Tabs.Panel value="valoraciones">
                    <CourseReviewsCard reviews={reviews}/>
                </Tabs.Panel>
            </Tabs>
        </div>
    );
};

export default CourseTab;
