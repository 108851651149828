import './AboutUsContainer.css'
import dog from '../../../../assets/aboutUs/aboutUs_1.png'
import dog2 from '../../../../assets/aboutUs/aboutUs_2.png'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import AboutUsDesktop from '../AboutUsDesktop/AboutUsDesktop'
import AboutUsMobile from '../AboutUsMobile/AboutUsMobile'

export default function AboutUsContainer() {
   const {width} = useWindowDimensions();
   const isMobile = width <= 768;
   return (
      <section className='aboutUs-section'>
         <div className='aboutUs-first-container'>
            {
               isMobile 
                  ? < AboutUsMobile firstImage={dog} secondImage={dog2} /> 
                  : < AboutUsDesktop firstImage={dog} secondImage={dog2} />
            }
         </div>
      </section>
   )
}
