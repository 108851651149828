import './TransferBankSendOptions.css'
import { useState } from 'react';
import { Button, FileInput, Loader } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import IconFilled from '../../../components/IconFilled/IconFilled';
import axios from "axios";
import { useAppContext } from '../../../context/AppState/AppContext';
import { openUrl } from "../../../utils/openUrl";
import TransferBankConfirmModal from '../TransferBankConfirmModal/TransferBankConfirmModal';
import showNotification from "../../../utils/showNotification";

export default function TransferBankSendOptions() {
   const [file, setFile] = useState(null)
   const [isLoading, setIsLoading] = useState(false)
   const [error, setError] = useState(null)
   const [modal, setModal] = useState(false)

   const { paymentData } = useAppContext()

   if (!paymentData) return null

   const email = paymentData.postData.data?.email
   const timestamp = paymentData?.timestamp
   const postData = paymentData?.postData
   const ALLOWED_FILES_TYPES = ['image/png', 'image/jpeg', 'application/pdf']
   const MAX_SIZE_IN_BYTES = 5 * 1024 * 1024 // 5 mb

   const handleUpload = async (event, withFile) => {
      event.preventDefault()

      if (withFile) {
         if (!file) {
            setError('Por favor, selecciona un archivo')
            setTimeout(() => setError(null), 4000)
            return;
         }

         if (!ALLOWED_FILES_TYPES.includes(file.type)) {
            setError('Formato del archivo no permitido')
            setTimeout(() => setError(null), 4000)
            return
         }

         if (file.size > MAX_SIZE_IN_BYTES) {
            setError('El archivo es demasiado grande (menos de 5mb)')
            setTimeout(() => setError(null), 4000)
            return
         }
      }
      /* validacion para los dos caminos */
      if (!email || !timestamp || !postData) {
         setError('Lo siento. Ha ocurrido un error.')
         setTimeout(() => setError(null), 4000)
         return
      }

      setIsLoading(true)

      const formData = new FormData();
      formData.append('postData', JSON.stringify(postData))
      // agrega el archivo y un nombre para el mismo
      if (withFile) {
         formData.append('name', `${email}-${timestamp}`);
         formData.append('file', file);
      }

      try {
         const response = await axios.post(process.env.REACT_APP_BACKEND_SERVER + '/payment/transfer', formData, {
            headers: {
               'Content-Type': 'multipart/form-data'
            }
         });
         openUrl(response.data.data, false);
      } catch (err) {
         showNotification({
            color: 'red',
            status: 'error',
            title: 'Operación fallida.',
            message: `Detalles: ${err?.response?.data?.error || "Sin detalles"}`,
         });

         // if (err?.response?.data?.data) {
         //    openUrl(err.response.data.data, false);
         // }
      } finally {
         setIsLoading(false)
      }
   };
   return (
      <>
         <TransferBankConfirmModal modal={modal} setModal={setModal} isLoading={isLoading}
                                   onClick={(event) => handleUpload(event,false)} />
         <div className='transfer-bank-send-options-container'>

            <div className='transfer-bank-send-options--column'>
               <div className='transfer-bank-options-fileInput-container'>
                  <FileInput
                     radius="md"
                     label='Cargá tu archivo'
                     description="Archivos permitidos: JPG, PNG, PDF"
                     placeholder="No hay ningun archivo"
                     accept="image/png,image/jpeg,.pdf"
                     onChange={setFile}
                     clearable
                     w={'100%'}
                  />
                  <Button variant='filled' bg={'secondaryColor'} style={{ zIndex: 5 }} onClick={(event) => handleUpload(event,true)} disabled={!file || isLoading} w={{ base: '100%', sm: '50%', lg: '100%' }}>
                     {isLoading ? <Loader size={20} color="var(--color-secondary)" /> : 'Enviar comprobante'}
                  </Button>

                  {/* error */}
                  <div style={{ position: 'absolute', bottom: error ? '-50px' : '0', width: '100%', padding: '10px', borderRadius: 'var(--border-radius-sm)', backgroundColor: 'var(--color-red--light)', boxShadow: 'var(--box-shadow)', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', transition: 'all .3s', opacity: error ? '1' : '0', visibility: error ? 'visible' : 'hidden' }}>
                     <IconFilled backgroundColor='var(--color-text-primary)' rounded='50%'>
                        < IconX height={30} width={30} color='var(--color-red--light)' />
                     </IconFilled>
                     <p style={{ fontSize: 'var(--fs-body)', color: 'var(--color-text-primary)' }}>{error}</p>
                  </div>
               </div>

               <div className='transfer-bank-options-manual-container'>
                  <p>¿Ya completaste el proceso enviando el comprobante a tu asesor?</p>
                  <Button m={'auto'} w={{ base: '100%', sm: '50%', lg: '100%' }} onClick={() => setModal(true)} variant='outline' >Finalizar proceso</Button>
               </div>
            </div>
         </div>
      </>
   )
}


