import { Accordion } from '@mantine/core';
import axios from 'axios';
import { useEffect, useState } from 'react';
import './PreguntasFrecuentesContainer.css';
import ThinkingDog from '../../assets/FrecuentQuestions/attention.gif';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ContactCard from '../Contact/ContactCard/ContactCard';

const PreguntasFrecuentesContainer = () => {
  const { width } = useWindowDimensions();
  const isMobile = width <= 768;
  const [faqs, setFaqs] = useState([]);
  const [isMount, setIsMount] = useState(true);

  const fetchFaqs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_SERVER}/admin/faqs`
      );
      console.log(response);
      const formattedResponse = response.data.map((faq) => ({
        name: faq.name,
        question: faq.question,
        id: faq._id,
        answer: faq.answer,
      }));
      setFaqs(formattedResponse);
      console.log(formattedResponse);
    } catch (error) {
      console.error("Error fetching FAQs data:", error);
    }
  };

  useEffect(() => {
    if (isMount) {
      setIsMount(false);
    } else {
      fetchFaqs();
    }
  }, [isMount]);

  return (
    <div className='preguntasFrecuentesContainer-father-div'>
      <div className="preguntasFrecuentesContainer-grid">
        {isMobile ? (
          <>
            <div className='preguntasFrecuentesContainer-content'>
              <h2 className="preguntasFrecuentesContainer-title">
                Preguntas frecuentes
              </h2>
              <Accordion
                classNames={{ label: 'preguntasFrecuentesContainer-accordion-labels', panel: 'preguntasFrecuentesContainer-accordion-panel' }}
                chevronPosition="right"
                variant="separated"
              >
                {faqs.map((faq) => (
                  <Accordion.Item key={faq.id} value={faq.id}>
                    <Accordion.Control>{faq.question}</Accordion.Control>
                    <Accordion.Panel>{faq.answer}</Accordion.Panel>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className='preguntasFrecuentesContainer-contactCard-div'>
              <ContactCard />
            </div>
          </>
        ) : (
          <>
            <div className='preguntasFrecuentesContainer-div-image'>
              <img className='preguntasFrecuentesContainer-image' src={ThinkingDog} alt="Frequently Asked Questions" />
            </div>
            <div className='preguntasFrecuentesContainer-content'>
              <h2 className="preguntasFrecuentesContainer-title">
                Preguntas frecuentes
              </h2>
              <Accordion
                classNames={{ label: 'preguntasFrecuentesContainer-accordion-labels', panel: 'preguntasFrecuentesContainer-accordion-panel' }}
                chevronPosition="right"
                variant="separated"
              >
                {faqs.map((faq) => (
                  <Accordion.Item key={faq.id} value={faq.id}>
                    <Accordion.Control>{faq.question}</Accordion.Control>
                    <Accordion.Panel>{faq.answer}</Accordion.Panel>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className='preguntasFrecuentesContainer-contactCard-div'>
              <h4 className='preguntasFrecuentesContainer-contactCard-text'>Nuestro servicio de asistencia personalizada está ansioso por resolver tus dudas. ¡Aprovéchalo!</h4>
              <ContactCard />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PreguntasFrecuentesContainer;