import '@mantine/carousel/styles.css';
import './AvalContainer.css'
import { Skeleton } from '@mantine/core';
import AvalCarouselContainer from '../../../../components/AvalsCarousel/AvalCarouselContainer/AvalCarouselContainer';
import { useEffect, useState } from 'react';
import axios from 'axios';

export default function AvalContainer() {
   const [ avals, setAvals ] = useState([])
   const [ isLoading, setIsLoading ] = useState(false)

   useEffect(() => {
      setIsLoading(true)
      const url = process.env.REACT_APP_BACKEND_SERVER + '/info/avals';
      axios.get(url)
         .then(response => setAvals(response.data))
         .catch(err => console.log(err))
         .finally(() => setIsLoading(false))
   }, [])
   
   return (
      <section className='avalContainer'>
         <div className='avalSection-container'>
            <h2 className='avalSection-title'>NOS APOYAN</h2>
            {isLoading && 
               <div className='skeleton-container'>
                  <Skeleton height={90} width={'auto'} circle />
                  <Skeleton height={90} width={'auto'} circle />
                  <Skeleton height={90} width={'auto'} circle />
                  <Skeleton height={90} width={'auto'} circle />
               </div>
            }
            {!isLoading && avals && < AvalCarouselContainer avals={avals} />}
         </div>
      </section>
   );
}
