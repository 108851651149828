import {Avatar} from "@mantine/core";
import "./CourseProfessorAvatar.css";

const CourseProfessorAvatar = ({
                                   professor,
                                   index,
                                   selectedProfessor,
                                   setSelectedProfessor,
                               }) => {
    const isCurrentProfessorSelected = selectedProfessor === index;

    const handleClick = () => {
        setSelectedProfessor(index);
    };

    return (
        <div className="courseProfessorAvatar-first-section-div">
            <div
                className={
                    isCurrentProfessorSelected
                        ? "courseProfessorAvatar-name-and-role-div-active"
                        : "courseProfessorAvatar-name-and-role-div-inactive"
                }
                onClick={handleClick}
            >
                <Avatar size={104} src={professor.profilePicture}/>
                <div className="courseProfessorAvatar-name-and-role">
                    <h4 className="courseProfessorAvatar-role-title-h4">
                        {professor.name}
                    </h4>
                    <h5 className="courseProfessorAvatar-role-title-h5">
                        {professor.role}
                    </h5>
                </div>
            </div>
        </div>
    );
};

export default CourseProfessorAvatar;
