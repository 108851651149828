import './TransferBankPage.css'
import BankAccountContainer from '../../features/TransferBankPage/BankAccountContainer/BankAccountContainer'
import TransferBankHero from '../../features/TransferBankPage/TransferBankHero/TransferBankHero'
import TransferBankSteps from '../../features/TransferBankPage/TransferBankSteps/TransferBankSteps'
import MainLayout from '../../layouts/MainLayout/MainLayout'
import StepsItem from '../../features/TransferBankPage/StepsItem/StepsItem'
import { IconShieldCheckFilled } from '@tabler/icons-react'
import TransferBankSendOptions from '../../features/TransferBankPage/TransferBankSendOptions/TransferBankSendOptions'
import DecorativeCircle from "../../components/DecorativeCircle/DecorativeCircle";
import TinyDecorativeCircle from "../../components/TinyDecorativeCircle/TinyDecorativeCircle";
import { useAppContext } from '../../context/AppState/AppContext'

export default function TransferBankPage() {
   const { width } = useAppContext()
   const isMediumDimension = width <= 1200;

    return (
        <MainLayout overflow="hidden">
            <section className='transfer-bank-page__section' style={{position: "relative"}}>
                < TransferBankHero/>
                <DecorativeCircle size={100} top={-20} left={-20} opacity={0.25} shadow={1}/>
                <DecorativeCircle size={150} bottom={-100} left={300} opacity={0.2} shadow={1}/>
                <DecorativeCircle size={200} bottom={-200} right={-200} opacity={0.2} shadow={2}/>
                <TinyDecorativeCircle size={12} top={50} right={100} opacity={1} color={1}/>
                <TinyDecorativeCircle size={32} top={100} left={900} opacity={1} color={3}/>
                <TinyDecorativeCircle size={48} top={300} left={750} opacity={1} color={3}/>
            </section>
            <section className='transfer-bank-page__section transfer-bank-page--content'>
                < TransferBankSteps/>

            < BankAccountContainer />

            {isMediumDimension && < TransferBankSendOptions />}
         </section>
         <section className='transfer-bank-page__section'>
            < StepsItem
               backgroundColor='var(--color-secondary)'
               icon={< IconShieldCheckFilled width={40} height={40} stroke-width={2}
                  className='transfer-bank-steps-item--icon' />}
               text='Nos caraterizamos por ser una empresa comprometida por sus clientes.'
            />
         </section>
      </MainLayout>
   )
}
