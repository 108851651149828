import React from 'react';
import './CourseDetailsCard.css';
import {IconStack2, IconBooks, IconCertificate, IconDeviceLaptop} from '@tabler/icons-react';
import {Badge, Button} from '@mantine/core';
import CardFeature from "../../../components/CardFeature/CardFeature";
import {currencyFormat} from "../../../utils/currencyFormat";
import {useNavigate} from 'react-router-dom';
import {useAppContext} from '../../../context/AppState/AppContext';
import CourseCardModal from './CourseCardModal/CourseCardModal';
import { formattedPrice } from '../../../utils/formatted-price';

const COURSE_INCLUDES_DEFAULT = [
    {
        icon: <IconStack2 className="cardFeature__icon"/>,
        text: "Modalidad 100% online",
    },
    {
        icon: <IconBooks className="cardFeature__icon"/>,
        text: "Material de soporte para el curso",
    },
    {
        icon: <IconCertificate className="cardFeature__icon"/>,
        text: "Certificado emitido por FORVET",
    },
    {
        icon: <IconDeviceLaptop className="cardFeature__icon"/>,
        text: "Clases grabadas",
    },
]

function CourseDetailsCard(props) {
    const {price, features = null, handleInscription, isOpen, courseData} = props;
    const {isUSD} = useAppContext();
    const navigate = useNavigate();

    return (
        <div className="courseDetailsCard__container">
            <span className="courseDetailsCard__price">{formattedPrice(currencyFormat(price), isUSD)}</span>
            <Badge
                classNames={{root: "courseDetailsCard__badge"}}
                variant="light" size="md" radius="sm"
            >
                {isOpen ? '¡INSCRIPCIONES ABIERTAS!' : '¡INSCRIPCIONES CERRADAS'}
            </Badge>

            <CourseCardModal/>

            <hr/>
            {features.map((item, index) => (
                <CardFeature
                    key={index}
                    icon={item.icon}
                    text={item.text}
                    description={item.description}
                />
            ))}

            <hr/>
            <div className="courseDetailsCard__button__container">
                <Button className="courseDetailsCard__button" color='secondaryColor' fw='normal' disabled={!isOpen} fullWidth onClick={() => handleInscription()}>
                    Inscribirse
                </Button>
                <Button className="courseDetailsCard__button" fw='normal' fullWidth
                        variant="default" onClick={() => navigate("/contacto", { state: courseData })}>
                    Consultar un asesor
                </Button>
            </div>
            <hr className="courseDetailsCard__hr"/>

            {COURSE_INCLUDES_DEFAULT.map((item, index) => (
                <CardFeature
                    key={index}
                    icon={item.icon}
                    text={item.text}
                />
            ))}
        </div>
    );
}

export default CourseDetailsCard;