import React, {useEffect, useState} from "react";
import TestimonialElement from "../TestimonialElement/TestimonialElement";
import "./TestimonialGrid.css";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import axios from "axios";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const TestimonialGrid = () => {
    const [testimonials, setTestimonials] = useState([]);
    const {width} = useWindowDimensions();
    const isMobile = width <= 768;

    const fetchTestimonials = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_SERVER}/info/testimonies`
            );
            const formattedResponse = response.data.map((testimonial) => {
                return {
                    name: testimonial.name,
                    image: testimonial.profilePicture,
                    text: testimonial.review,
                    id: testimonial._id,
                };
            });
            setTestimonials(formattedResponse);
        } catch (error) {
            console.error("Error fetching testimonials data:", error);
        }
    };

    useEffect(() => {
        fetchTestimonials();
    }, []);

    return (
        <div className="testimonialGrid-grid-wrapper">
            <SectionTitle upText="testimonios" downText="Nuestros Alumnos Dicen" />
    
            <div className="testimonialGrid-grid-container">
                <ResponsiveMasonry columnsCountBreakPoints={{ 768: 1, 1200: 3 }}>
                    <Masonry gutter="10px">
                        {isMobile
                            ? testimonials.slice(0, 1).map((testimonial) => (
                                  <TestimonialElement
                                      key={testimonial.id}
                                      name={testimonial.name ? testimonial.name : "Unknown"}
                                      image={testimonial.image}
                                      text={testimonial.text ? testimonial.text : "No hay datos que mostrar"}
                                  />
                              ))
                            : testimonials.map((testimonial) => (
                                  <TestimonialElement
                                      key={testimonial.id}
                                      name={testimonial.name ? testimonial.name : "Unknown"}
                                      image={testimonial.image}
                                      text={testimonial.text ? testimonial.text : "No hay datos que mostrar"}
                                  />
                              ))}
                    </Masonry>
                </ResponsiveMasonry>
            </div>
        </div>
    );
    
};

export default TestimonialGrid;