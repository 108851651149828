  import { Avatar } from "@mantine/core";
  import "./TestimonialElement.css";

  const TestimonialElement = (props) => {
    const { name, image, text } = props;

    return (
      <>
        <div className="testimonialElement-father-div">
          <blockquote>
            {text}
          </blockquote>
          <div className="author">
            <div className="testimonialElement-avatar-container">
              <Avatar src={image} alt="Avatar testimonial section" radius="50%" size={100} />
            </div>
            <h5>
              {name}
            </h5>
          </div>
        </div>
      </>
    );
  };
  export default TestimonialElement;
