import { useDisclosure } from '@mantine/hooks';
import { Modal, Text } from '@mantine/core';
import './PromotionModal.css';

function PromotionModal({ title, message, courses, actions }) {
    const [opened, { open, close }] = useDisclosure(true);

    return (
        <>
            <Modal opened={opened}
                   onClose={close}
                   title={title}
                   centered size="md"
                   classNames={{
                       title: "promotionModal__title",
                   }}
            >
                <Text size="lg" weight={500} ta="center">{message}</Text>

                <div className="promotionModal__container">
                    {courses.map((course) => (
                        <Text size="sm">✅ {course}</Text>
                    ))}
                </div>

                <div className="promotionModal__container--row">
                    {actions}
                </div>
            </Modal>
        </>
    );
}

export default PromotionModal;