import ReactPixel from 'react-facebook-pixel';

const initFbPixel = () => {
    const options = {
        autoConfig: true,
        debug: false,
    };
    ReactPixel.init(process.env.REACT_APP_META_PIXEL_ID, {}, options);
}

export { initFbPixel };