import './TeamHeader.css'

export default function TeamHeader() {
   return (
      <div className="teamHeader-container">
         <h2 className="teamHeader-title">EQUIPO</h2>
         <p className='teamHeader-paragraph'>
            En FORVET, nuestro mayor activo es nuestra comunidad de expertos apasionados y comprometidos.
            Conformamos un equipo diverso y talentoso de profesionales veterinarios dedicados a elevar la profesión y compartir nuestro conocimiento con la comunidad.</p>
         <p className='teamHeader-paragraph'>
            Juntos, formamos una red internacional de colegas que persiguen la excelencia en la Medicina Veterinaria.
         </p>
      </div>
   )
}
