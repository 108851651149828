import ContactCard from "../../features/Contact/ContactCard/ContactCard";
import './Contact.css';
import MainLayout from "../../layouts/MainLayout/MainLayout";
import {useLocation} from "react-router";

const Contact = () => {
    const { state: courseData } = useLocation();

    return (
        <MainLayout overflow="hidden">
            <section className="contact-section">
                <ContactCard courseData={courseData}/>
            </section>
        </MainLayout>
    )
}
export default Contact