import "./TransferBankCart.css";
import { useAppContext } from "../../../context/AppState/AppContext";
import { IconCreditCard, IconTag } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { currencyFormat } from "../../../utils/currencyFormat";
import { formattedPrice } from "../../../utils/formatted-price";
import {calculateDiscountedPrice} from "../../../utils/calculateDiscountedPrice";

export default function TransferBankCart() {
   const { paymentData, isUSD } = useAppContext();
   const navigate = useNavigate();

   if (!paymentData) {
      return navigate(-1);
   }

   const { name, image, price, postData } = paymentData;

   return (
      <article className="transfer-bank-cart-container">
         <div className="transfer-bank-cart-header">

            <figure className="transfer-bank-cart-image-container">
               <img className="transfer-bank-cart--image" src={image} alt={name} />
            </figure>

            <div className="transfer-bank-cart-info--group">
               <h4 className="transfer-bank-cart-buyName">{name}</h4>
            </div> 

         </div>
         <div className="transfer-bank-cart-info">

            <div className="transfer-bank-cart-info--group">
               <IconTag className="transfer-bank-cart-info--icon" />
               <h4 className="transfer-bank-cart-info--text">
                  Total a pagar:{" "}
                  <strong>
                     {formattedPrice(currencyFormat(calculateDiscountedPrice(price, postData?.data?.discount?.percent || 0)), isUSD)}
                  </strong>
               </h4>
            </div>

            <div className="transfer-bank-cart-info--group">
               <IconCreditCard className="transfer-bank-cart-info--icon" />
               <h4 className="transfer-bank-cart-info--text">Transferencia bancaria</h4>
            </div>

         </div>
      </article>
   );
}


/* 

<article className="transfer-bank-cart-container">
      <figure className="transfer-bank-cart-image-container">
        <img className="transfer-bank-cart--image" src={image} alt={name} />
      </figure>
      <div className="transfer-bank-cart-info">
        <div className="transfer-bank-cart-info--group">
          <h4 className="transfer-bank-cart-buyName">{name}</h4>
        </div>

        <div className="transfer-bank-cart-info--group">
          <IconCreditCard className="transfer-bank-cart-info--icon" />
          <h4>Transferencia bancaria</h4>
        </div>

        <div className="transfer-bank-cart-info--group">
          <IconTag className="transfer-bank-cart-info--icon" />
          <h4>
            Total a pagar:{" "}
            <strong>{formattedPrice(currencyFormat(calculateDiscountedPrice()), isUSD)}</strong>
          </h4>
        </div>
      </div>
    </article>


*/