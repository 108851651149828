import {hundredRounder} from "./hundredRounder";

const calculatePrice = (_prices, _officialUSDValue, _isUSD, _type) => {
    if (_isUSD) {
        return _prices.usd;
    } else {
        if (
            _officialUSDValue !== undefined &&
            _officialUSDValue !== null &&
            _officialUSDValue !== 0 &&
            _type !== "Masterclass" &&
            _type !== "Taller" &&
            _type !== "Curso"
        ) {
            const calculatedPrice = _prices.usd * _officialUSDValue;
            return hundredRounder(calculatedPrice, 'up');
        } else {
            return _prices.ars;
        }
    }
}

export {calculatePrice}