import "./PaymentPageLogo.css";

const PaymentPageLogo = ({
  showSadImage,
  imageUrl,
  height = 180,
}) => {
  const containerClass = showSadImage ? "paymentPageLogo-imageOverShapeCancel-container" : "paymentPageLogo-imageOverShape-container";
  const shapeClass = showSadImage ? "paymentPageLogo-imageOverShapeCancel-shape" : "paymentPageLogo-imageOverShape-shape";
  const imageClass = showSadImage ? "paymentPageLogo-imageOverShapeCancel-image" : "paymentPageLogo-imageOverShape-image";
  return (
    <div
      className={containerClass}
      style={{ height }}
    >
      <div
        className={shapeClass}
      />
      <img
        className={imageClass}
        src={imageUrl}
        alt="course_hero"
        style={{ height: `${height * 1.1}px` }}
      />
    </div>

  );
};
export default PaymentPageLogo;
