import SectionTitle from '../../../components/SectionTitle/SectionTitle'
import './CourseRequirements.css'

export default function CourseRequirements({text}) {
   return (
      <div className='course-requirements-container'>
         <SectionTitle upText='requisitos' downText='Para quien es este curso' />
         <p className='course-requirements-description'>
             {text}
         </p>
      </div>
   )
}
