import SectionTitle from '../../../../components/SectionTitle/SectionTitle'
export default function AboutUsMobile({ firstImage, secondImage }) {
   return (
      <>
         <div className='aboutUs-text-container'>
            < SectionTitle upText='sobre nosotros' downText='Una comunidad de profesionales especialistas' />
         </div>
         <div className='aboutUs-pictures-container'>
            <div className='aboutUs-image-container'>
               <img className='aboutUs-image' src={firstImage} alt='perro contento' />
               <img className='aboutUs-image' src={secondImage} alt='perro contento' />
            </div>
         </div>
         <p className='aboutUs-paragraph'>FORVET es una comunidad apasionada de expertos veterinarios que comparten su vasta experiencia para formar especialistas de la más alta calidad. Donde el aprendizaje surge de la colaboración de una red internacional de colegas comprometidos con la redefinición del estándar de la profesión veterinaria.</p>
      </>
   )
}
