import React, {useState} from 'react';
import './CreateSpecialtyForm.css';
import {useForm} from "@mantine/form";
import {TextInput, Button, Grid, Textarea} from "@mantine/core";
import axios from "axios";
import showNotification from "../../../utils/showNotification";

function CreateSpecialtyForm({updateData, setUpdateData, setOpen}) {
    const [loading, setLoading] = useState(false);
    const form = useForm({
        initialValues: {
            name: "",
            photo: "",
            description: "",
        },
        validate: {},
    });
    const handleSubmit = async (values) => {
        console.log(values)
        setLoading(true)

        const data = {
            data: {...values}
        }
        axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/admin/specialties`, data)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setUpdateData(!updateData)
                    setOpen(false)
                } else {
                    showNotification({
                        color: "red",
                        status: "error",
                        title: "Error",
                        message: `Error al crear la especialidad`,
                    });
                }
            })
            .catch((err) => {
                console.log(err)
                const error = err.message || err.response.data.message || err || "Error al crear la especialidad";
                showNotification({
                    color: "red",
                    status: "error",
                    title: "Error",
                    message: `Error al crear la especialidad: ${error}`,
                });
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className="createSpecialty__container">
            <h2 className="createSpecialty__title">Crear especialidad</h2>
            <form
                onSubmit={form.onSubmit((values) => handleSubmit(values))}
                className="createSpecialty__form"
            >
                <Grid style={{width: "100%"}}>
                    <Grid.Col span={12}>
                        <TextInput
                            {...form.getInputProps('name')}
                            withAsterisk
                            label="Nombre de la especialidad"
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            {...form.getInputProps('photo')}
                            withAsterisk
                            label="URL de la foto de la especialidad"
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Textarea
                            {...form.getInputProps('description')}
                            withAsterisk
                            label="Descripción de la especialidad"
                        />
                    </Grid.Col>
                </Grid>
                <Button type="submit" className="createSpecialty__button" loading={loading}>Crear especialidad</Button>
            </form>
        </div>
    );
}

export default CreateSpecialtyForm;