import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import "./ContactBanner.css";
import { Button } from "@mantine/core";
import ContactBannerImage from "../../../assets/ContactBannerAssets/contactbannerlanding.png";
import { useNavigate } from "react-router-dom";

const ContactBanner = () => {
  const navigate = useNavigate();
  const onClickHandle = () => {
    navigate("/contacto");
  };
  return (
    <div className="contactBanner-father-div">
      <div className="contactBanner-info-container">
        <div className="contactBanner-title-container">
          <SectionTitle
            upText="Contacto"
            downText="¿Quieres conocer más sobre nuestra propuesta?"
          />
        </div>
        <div className="contactBanner-button-div">
          <Button
            onClick={onClickHandle}
            
            fullWidth
            color="dark"
          >
            Contáctanos
          </Button>
        </div>
      </div>
      <div className="contactBanner-image-div-container">
        <img
          className="contactBanner-image-div-styling"
          src={ContactBannerImage}
        />
      </div>
    </div>
  );
};
export default ContactBanner;
