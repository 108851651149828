import SpecialtiesHero from '../../Specialties/SpecialtiesHero/SpecialtiesHero'
import image from '../../../assets/institutional/vet_catImg2.png'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

const description = `La capacidad de innovación, evolución y adaptación al cambio 
traducida en una oferta académica de excelencia.`

export default function PhilosophySection() {
   const {width} = useWindowDimensions()
   const isMobile = width <= 768
  return (
      < SpecialtiesHero height={isMobile ? 200 : 250}  title='Nos define' right='0px' imageUrl={image} description={description} imageBackgroundColor='#E08D97' square />
  )
}
