import {useState, useEffect, useRef} from "react";
import {Carousel} from "@mantine/carousel";
import Autoplay from 'embla-carousel-autoplay';
import axios from "axios";
import NewsCard from "../NewsCard/NewsCard";
import "./NewsCarousel.css";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";

const NewsCarousel = () => {
    const [activeSlide, setActiveSlide] = useState(0);
    const [newsdata, setNewsData] = useState([]);
    const autoplay = useRef(Autoplay({ delay: 3500 }));

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER}/info/News`);

            const formatedResponse = response.data.map((news) => {
                return {
                    title: news.title,
                    text: news.text,
                    image: news.image,
                    route: news.url,
                };
            });
            setNewsData(formatedResponse);

        } catch (error) {
            console.error("Error fetching news data:", error);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);


    return (
        <div className="div-container-newscarousel">
            <SectionTitle upText="Oferta Académica" downText="Novedades"/>

            <Carousel
                plugins={[autoplay.current]}
                onMouseEnter={autoplay.current.stop}
                onMouseLeave={autoplay.current.reset}
                withIndicators
                slideSize={{ base: '50%', sm: '50%', md: '80%' }}
                slideGap={{ base: 'md' }}
                loop
                height="464px"
                align="start"
                onSlideChange={(i) => setActiveSlide(i)}
                classNames={{
                    root: "newsCarousel__root",
                    container: "newsCarousel__container",
                }}
            >
                {newsdata.map((news, idx) => (
                    <Carousel.Slide>
                        <NewsCard
                            title={news.title}
                            route={news.route}
                            description={news.text}
                            image={news.image}
                            buttonText="Ver más"
                            active={activeSlide === idx}
                        />
                    </Carousel.Slide>
                ))}

            </Carousel>
        </div>
    );
};
export default NewsCarousel;
