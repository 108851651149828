import "./CoursePaymentForm.css";
import { Button, Flex, Grid, Modal, NumberInput, TextInput, Tooltip } from "@mantine/core";
import { createRef, useState } from "react";
import PhoneInputField from "../../../components/PhoneInputField/PhoneInputField";
import { IconCheckbox } from "@tabler/icons-react";

const CoursePaymentForm = ({ form, isRegistered, setIsRegistered }) => {
   const [whyAskInformationModal, setWhyAskInformationModal] = useState(false)
   const ref = createRef()

   return (
      <div className="coursePaymentForm-father-div">
         <div className="coursePaymentForm-father-container">
            <h3 className="coursePaymentForm-description-text">
               Completa la información para crear el perfil educativo
            </h3>
            <p className="coursePaymentForm-already-have-account" onClick={() => { setIsRegistered(!isRegistered) }}>
               {isRegistered ? "Aún no tengo cuenta en FORVET" : "¡Ya tengo una cuenta en ForVet!"}
            </p>
            <div className="coursePaymentForm-form-div">
               <form className="coursePaymentForm-form-styling" onSubmit={form.onSubmit(console.log)}>
                  {!isRegistered &&
                     <TextInput
                        classNames={{
                           root: "coursePaymentForm-first-textInput",
                           label: "coursePaymentForm-textInput-labels",
                           wrapper: "coursePaymentForm-textInput-wrapper",
                           error: "coursePaymentForm-textInput-error",
                        }}
                        label="Nombre"
                        placeholder="Nombre"
                        {...form.getInputProps("name")}
                        onBlur={(event) =>
                           form.setFieldValue("name", event.currentTarget.value)
                        }
                     />
                  }
                  {!isRegistered &&
                     <TextInput
                        classNames={{
                           root: "coursePaymentForm-second-textInput",
                           label: "coursePaymentForm-textInput-labels",
                           wrapper: "coursePaymentForm-textInput-wrapper",
                           error: "coursePaymentForm-textInput-error",
                        }}
                        label="Apellido"
                        placeholder="Apellido"
                        {...form.getInputProps("apellido")}
                        onBlur={(event) =>
                           form.setFieldValue("apellido", event.currentTarget.value)
                        }
                     />
                  }
                  <TextInput
                     classNames={{
                        root: "coursePaymentForm-third-textInput",
                        label: "coursePaymentForm-textInput-labels",
                        wrapper: "coursePaymentForm-textInput-wrapper",
                        error: "coursePaymentForm-textInput-error",
                     }}
                     mt="sm"
                     label="Email de registro al campus"
                     placeholder="Email"
                     {...form.getInputProps("email")}
                     onBlur={(event) =>
                        form.setFieldValue("email", event.currentTarget.value)
                     }
                  />
                  <TextInput
                     classNames={{
                        root: "coursePaymentForm-third-textInput",
                        label: "coursePaymentForm-textInput-labels",
                        wrapper: "coursePaymentForm-textInput-wrapper",
                        error: "coursePaymentForm-textInput-error",
                     }}
                     mt="sm"
                     label="Confirmación de email de registro al campus"
                     placeholder="Email"
                     {...form.getInputProps("emailConfirmation")}
                     onBlur={(event) =>
                        form.setFieldValue("emailConfirmation", event.currentTarget.value)
                     }
                  />
                  {!isRegistered &&
                     <PhoneInputField
                        ref={ref}
                        name="telefono"
                        fieldError={form.errors.telefono}
                        {...form.getInputProps("telefono")}
                        onChange={(value) => {
                           form.setFieldValue("telefono", value)
                        }}
                     />
                  }
                  <Tooltip
                      position="bottom"
                      label="Debes completar con tu número de Documento de Identidad" >
                     <TextInput
                        classNames={{
                           root: "coursePaymentForm-second-textInput",
                           label: "coursePaymentForm-textInput-labels",
                           wrapper: "coursePaymentForm-textInput-wrapper",
                           error: "coursePaymentForm-textInput-error",
                        }}
                        mt="sm"
                        label="Número de identificación"
                        placeholder="Numero de Documento de Identidad o Cédula de Identidad"
                        {...form.getInputProps("dni")}
                        onBlur={(event) =>
                           form.setFieldValue("dni", event.currentTarget.value)
                        }
                     />
                  </Tooltip>

               </form>
            </div>
            <p className="coursePaymentForm-note">
               Los datos personales proporcionados en este formulario serán utilizados exclusivamente para la
               creación de tu cuenta de usuario en nuestra plataforma educativa. Si ya posees una cuenta previa,
               por favor ingresa el correo electrónico asociado a la misma para vincular el curso a tu perfil
               existente.
            </p>
            <p className="coursePaymentForm-note">
               Es importante destacar que el individuo que realiza el pago no necesita ser necesariamente el mismo
               usuario que accederá al curso. Los detalles de facturación y pago se solicitarán en la plataforma de
               pago externa. Los datos requeridos en este formulario son fundamentales para el registro y
               asociación del curso al usuario correspondiente.
            </p>
            <Button
               variant="transparent"
               p={0}
               my={10}
               style={{ textDecoration: 'underline',fontSize: 'var(--fs-tiny)', fontWeight: '400' }}
               onClick={() => setWhyAskInformationModal(true)}
            >
               ¿Para qué pedimos estos datos?
            </Button>
            <Modal
               opened={whyAskInformationModal}
               onClose={() => setWhyAskInformationModal(false)}
            >
               <Grid>
                  <Grid.Col span={12}>
                     <h3 className="CoursePaymentForm--modalTitle">¿Para qué pedimos estos datos?</h3>
                  </Grid.Col>
                  <Grid.Col span={12} >
                     <Flex align={'start'} gap={6} mt={6}>
                        <IconCheckbox style={{ flexShrink: 0 }} />
                        <p className="whyAskInformation-text">Los datos que solicitamos en este formulario nos permiten registrar tu usuario en nuestra plataforma educativa y asociar los cursos correspondientes a tu cuenta.</p>
                     </Flex>
                     <Flex align={'start'} gap={6} mt={6}>
                        <IconCheckbox style={{ flexShrink: 0 }} />
                        <p className="whyAskInformation-text">Por otro lado, estos datos son necesarios para cumplir con la reglamentación impositiva, asegurando la emisión de factura correspondiente y el registro de la transacción.</p>
                     </Flex>
                  </Grid.Col>
                  <Grid.Col span={12}>
                     <Flex w={'100%'} justify='center' align='center'>
                        <Button variant="outline" onClick={() => setWhyAskInformationModal(false)}>Aceptar y cerrar</Button>
                     </Flex>
                  </Grid.Col>
               </Grid>
            </Modal>
         </div>
      </div>
   );
};

export default CoursePaymentForm;
