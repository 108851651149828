
// agregar todos los err.code que no existan.
const ERROR_HANDLERS = {
   ERR_NETWORK: 'Lo siento, ha ocurrido un error en nuestra red',
   defaultError: 'Lo siento, ha ocurrido un error. Intentaremos solucionarlo lo antes posible.',
}

export const errorHandler = (err) => {
   console.log(err)

   return ERROR_HANDLERS[err.code] || ERROR_HANDLERS.defaultError
}