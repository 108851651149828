import { Image } from "@mantine/core";
import React from "react";
import { Link } from "react-router-dom";
import "./SpecialtiesCard.css";

const SpecialtiesCard = (props) => {
   const { name, route, image } = props;
   return (
      <Link className="specialtiesCard-link-navigation" to={route}>
         <div className="specialtiesCard-container-div">
            <Image className="specialtiesCard-image-custom" src={image} />
            <div className="specilatiesCard-content-container">
               <h2 className="specialtiesCard-title-for-specialties">{name}</h2>
            </div>
         </div>
      </Link>
   );
};
export default SpecialtiesCard;
