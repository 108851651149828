import React from 'react';
import './PhoneInputField.css';
import {TextInput} from "@mantine/core";
import PhoneInput from "react-phone-number-input";

function PhoneInputField(props) {
    return (
        <div className="PhoneInputField__container">
            <label className="PhoneInputField__label">Teléfono</label>
            <PhoneInput
                defaultCountry="AR"
                {...props}
                inputComponent={TextInput}
            />
            {props.fieldError && <p className="PhoneInputField__error">El número ingresado no es válido</p>}
        </div>
    );
}

export default PhoneInputField;