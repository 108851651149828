import { Skeleton } from "@mantine/core";
import './CardInfoSkeleton.css'
export default function CardInfoSkeleton({ height = 20 }) {
   return (
      <div className='card-skeleton-container'>
         <Skeleton height={height} radius="sm" />
         <Skeleton height={height} radius="sm" />
         <Skeleton height={height} radius="sm" />
      </div>
   )
}
