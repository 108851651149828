import React from 'react';
import './CardFeature.css';

function CardFeature(props) {
    const {icon, text, description = null} = props;
    return (
        <div className={description ? "cardFeature__container" : "cardFeature__container--fullwidth"} {...props}>
            <div className="cardFeature__main">
                {icon}
                <span className="cardFeature__text">{text}</span>
            </div>
            {description &&
                <span className="cardFeature__description">{description}</span>
            }
        </div>
    );
}

export default CardFeature;