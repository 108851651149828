import React from 'react';
import './ContactCard.css';
import {IconSun, IconAt, IconBrandWhatsapp} from '@tabler/icons-react';
import ContactCardIcon from "../../../components/ContactCardIcon/ContactCardIcon";
import ContactForm from "../ContactForm/ContactForm";
import {emailTo} from "../../../utils/emailTo";
import {wappTo} from "../../../utils/wappTo";

const SUBJECT = 'Consulta desde la web';
const MESSAGE = '¡Hola, buenos días! Me contacto desde la web para consultar por...';

const CONTACT_DATA = [
    {title: 'Email', description: ['administracion@forvet.org'], icon: IconAt, onClick: (to) => emailTo(to, SUBJECT)},
    {title: 'Telefono', description: ['+54 9 351 208 6545', '+54 9 351 857 1139'], icon: IconBrandWhatsapp, onClick: (to) => wappTo(to.replace(/\s/g, '') , MESSAGE)},
    {title: 'Horario de atención', info: 'Huso horario: Argentina', description: ['8 a.m. – 8 p.m.'], icon: IconSun},
];

function ContactCard({courseData}) {
    const items = CONTACT_DATA.map((item, index) => <ContactCardIcon key={index} {...item} />);
    return (
        <div className="contactCard-wrapper">
            <div className="contactCard-contacts">
                <h3 className="contactCard-title contactCard-title--left">
                    Contacto
                </h3>

                <div className="contactCard-items">
                    {items}
                </div>
            </div>

            <div className="contactCard-form">
                <h3 className="contactCard-title contactCard-title--right">
                    Envianos tu consulta
                </h3>

                <div className="contactCard-fields">
                    <ContactForm courseData={courseData}/>
                </div>
            </div>
        </div>
    );
}

export default ContactCard;