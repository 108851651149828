import './BubbleItem.css'
import BubbleOverlay from '../BubbleOverlay/BubbleOverlay';
import { Skeleton } from '@mantine/core';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions';
import { randomValueFromArray } from '../../../../../utils/randomValueFromArray';
import { IconUser } from '@tabler/icons-react';

export default function BubbleItem({ person, handleHover }) {
   const { width } = useWindowDimensions()
   const isMobile = width <= 768

   const generateRandomMargin = () => {
      const marginOptionsDesktop = [4, 8, 12]
      const marginOptionsMobile = [1, 4, 8]
      const marginLeft = randomValueFromArray(isMobile ? marginOptionsMobile : marginOptionsDesktop)
      const marginTop = randomValueFromArray(isMobile ? marginOptionsMobile : marginOptionsDesktop)
      const marginRight = randomValueFromArray(isMobile ? marginOptionsMobile : marginOptionsDesktop)
      const marginBottom = randomValueFromArray(isMobile ? marginOptionsMobile : marginOptionsDesktop)
      return { marginLeft, marginTop, marginRight, marginBottom }
   }

   const desktopBubbleHeight = ['56px', '80px', '88px']
   const mobileBubbleHeight = ['48px', '72px', '80px']
   const sizeOptions = isMobile ? mobileBubbleHeight : desktopBubbleHeight
   const height = randomValueFromArray(sizeOptions)

   const animationNameOptions = ['bubbleItemAnimation', 'bubbleItemAnimation2', 'bubbleItemAnimation3']

   const bubbleStyles = {
      height,
      width: height,
      position: generateRandomMargin(),
      borderRadius: '50%',
      transition: 'all 1s',
      display: 'grid',
      placeItems: 'center'
   }

   const noImageIconColorOptions = ['#fff', '#dadada', '#bdbdbd', '#ffe0e0']
   const noImageIconColor = randomValueFromArray(noImageIconColorOptions)
   return (
      <>
         {!person
            ?
            < Skeleton circle height={40} width={40} />
            :
            <div className={`bubble-item-container transform-${height}`}
               onMouseEnter={() => handleHover(true)}
               onMouseLeave={() => handleHover(false)}
               style={{
                  ...bubbleStyles,
                  marginLeft: `${bubbleStyles.position.marginLeft}px`,
                  marginRight: `${bubbleStyles.position.marginRight}px`,
                  marginTop: `${bubbleStyles.position.marginTop}px`,
                  marginBottom: `${bubbleStyles.position.marginBottom}px`,
                  animationDuration: `${Math.floor(Math.random() * (2001 - 500) + 500)}ms`, // entre 500 y 2000ms
                  animationName: randomValueFromArray(animationNameOptions)
               }}
            >
               {person?.profilePicture ?
                  < img
                     src={person.profilePicture}
                     style={{
                        borderRadius: 'inherit',
                        height,
                        width: height,
                        objectFit: 'cover',
                        boxShadow: '1px 1px 10px #00000038'
                     }}
                     alt={`foto de perfil de ${person.name}`}
                  />
                  : <div
                     style={{
                        borderRadius: 'inherit',
                        height,
                        width: height,
                        display: 'grid',
                        placeItems: 'center',
                        boxShadow: '1px 1px 10px #00000038',
                        backgroundColor: '#ffffff25'
                     }}
                  >
                     < IconUser
                        size={'60%'}
                        style={{ color: noImageIconColor }}
                     />
                  </div>
               }

               < BubbleOverlay
                  person={person}
                  bubbleSize={height}
               />
            </div>
         }
      </>
   )
}