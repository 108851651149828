import axios from "axios";
import { useEffect, useState } from "react";

/**
 * This custom hook is used get data from databse by a fetch function
 * @param url - The url of the image to be rendered.
 * @returns {data, error, isLoading}
 * @example 
 * const sponsorsData = useFetch(`${process.env.REACT_APP_BACKEND_SERVER}/admin/sponsors`)
 * const { data, error, isLoading} = sponsorsData
 * 
 * const {data, error, isLoading} = useFetch(`${process.env.REACT_APP_BACKEND_SERVER}/admin/sponsors`)
 */

export const useFetch = (url) => {
   const [data, setData] = useState(null)
   const [error, setError] = useState(null)
   const [isLoading, setIsLoading] = useState(false)

   useEffect(() => {
      if (!url) return

      const getData = () => {
         setIsLoading(true)
         axios.get(url)
            .then(({ data }) => setData(data))
            .catch(err => {
               console.log(err)
               setError(err)
            })
            .finally(() => setIsLoading(false))
      }

      getData()

   }, [url])

   return {
      data,
      error,
      isLoading
   }
}