import './TransferBankSteps.css'
import StepsItem from '../StepsItem/StepsItem';
import TransferBankSendOptions from '../TransferBankSendOptions/TransferBankSendOptions';
import {IconNumber1, IconNumber2, IconNumber3} from '@tabler/icons-react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export default function TransferBankSteps() {
    const {width} = useWindowDimensions()
    const isMediumDimension = width <= 1200;

    return (
        <div className='transfer-bank-steps-container'>
            <div className='transfer-bank-follow-steps-container'>

                <h4 className='transfer-bank-follow-steps-headline'>Para completar tu compra necesitamos que sigas los
                    siguientes pasos:</h4>

                <div className='transfer-bank-steps-items-container'>
                    <StepsItem StepsItem
                               icon={< IconNumber1 width={30} height={30} stroke-width={2}
                                                   className='transfer-bank-steps-item--icon'
                                                   color='var(--color-white)'/>}
                               text='Transfiere el monto indicado a una de las cuentas bancarias que se presentan.'
                    />

                    < StepsItem
                        icon={< IconNumber2 width={30} height={30} stroke-width={2}
                                            className='transfer-bank-steps-item--icon' color='var(--color-white)'/>}
                        text='Enviá tu comprobante de transferencia. Puedes adjuntarlo más abajo en esta web o enviarlo a tu asesor de ventas.'
                    />

                    < StepsItem
                        icon={< IconNumber3 width={30} height={30} stroke-width={2}
                                            className='transfer-bank-steps-item--icon' color='var(--color-white)'/>}
                        text='Un asesor verificará tu pago y aceptará tu pedido. Recibirás un correo electrónico notificando la inscripción.'
                    />

                    {!isMediumDimension && < TransferBankSendOptions/>}

                </div>

            </div>
        </div>
    )
}
