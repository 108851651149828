import whatsappIcon from '../assets/footer/whatsapp.svg'
import mailIcon from '../assets/footer/mail.svg'
import instagramIcon from '../assets/footer/instagram.svg'
import facebookIcon from '../assets/footer/facebook.svg'
import FaqIcon from '../assets/footer/faq-svgrepo-com.svg';

export const footerItems = [
   {
      text: '+54 9 351 208 6545',
      icon: whatsappIcon,
      isContactItem: true,
      to: 'https://wa.me/+5493512086545?text=¡Hola!%20Buenos%20días,%20tengo%20una%20consulta...',
      target:'_blank'
   },
   {
      text: 'Administración',
      icon: mailIcon,
      isContactItem: true,
      to: `mailto:administracion@forvet.org?subject=${encodeURIComponent('Consulta')}`,
      target:'_blank'
   },
   {
      text: 'Dirección Académica',
      icon: mailIcon,
      isContactItem: true,
      to: `mailto:direccion_academica@forvet.org?subject=${encodeURIComponent('Consulta')}`,
      target:'_blank'
   },
   {
      text: 'Comercial',
      icon: mailIcon,
      isContactItem: true,
      to: `mailto:ventas@forvet.org?subject=${encodeURIComponent('Consulta')}&body=${encodeURIComponent('Hola, me gustaría recibir información sobre su oferta académica.')}`,
      target:'_blank'
   },
   {
      text: 'Preguntas Frecuentes',
      icon: FaqIcon,
      isContactItem: true,
      to: '/preguntas-frecuentes'
   },
   {
      text: 'Forvetargentina',
      icon: instagramIcon,
      isContactItem: false,
      to: 'https://www.instagram.com/forvetargentina/',
      target:'_blank'
   },
   {
      text: 'Forvetargentina',
      icon: facebookIcon,
      isContactItem: false,
      to: 'https://www.facebook.com/forvetargentina/',
      target:'_blank'
   },
]