import './BankAccountContainer.css'
import { Skeleton } from '@mantine/core'
import { useFetch } from '../../../hooks/useFetch'
import BankAccount from '../BankAccount/BankAccount'

export default function BankAccountContainer() {
   const { data, isLoading, error } = useFetch(`${process.env.REACT_APP_BACKEND_SERVER}/info/bankAccounts`)
   
   return (
      <div className='bank-accounts-container'>

         <div className='bank-accounts--anyAccount'>
            <p>Podés transferir a cualquier de nuestras cuentas</p>
         </div>
         {error && <p style={{ fontSize: '16px' }}>Lo siento, no hemos podido cargar los datos.</p>}
         {isLoading && < Skeleton height={350} />}
         {!isLoading && data && data.map( item => (
            < BankAccount key={item._id} account={item} />
         ))
         }
      </div>
   )
}
