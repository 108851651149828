import {AppProvider} from './context/AppState/AppContext';
import './index.css';
import AppRoutes from './routes/AppRoutes';
import {useState} from "react";

function App() {
    const [theme, setTheme] = useState(0);
    const themesDict = {
        0: "dark-theme",
        1: "light-theme",
    }
    return (
        <AppProvider>
            <div className={themesDict[theme]}>
                <AppRoutes/>
            </div>
        </AppProvider>
    );
}

export default App;
