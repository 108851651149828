import AccountInfoItem from '../AccountInfoItem/AccountInfoItem'
import './BankAccount.css'
import {currencyFormat} from "../../../utils/currencyFormat";

export default function BankAccount({ account }) {
   const {accountCBU: cbu, accountAlias: alias, accountHolder: name, accountCUIT: cuit, accountType: type, accountBank: bank, accountNumber = null} = account
    if (accountNumber) {
        return (
            <article className='bank-account-container'>
                < AccountInfoItem headline='Número de cuenta:' text={accountNumber} canCopy />
                < AccountInfoItem headline='Nombre:' text={name} />
                < AccountInfoItem headline='DNI:' text={currencyFormat(cuit)} />
                < AccountInfoItem headline='Tipo:' text={type} />
                < AccountInfoItem headline='Banco:' text={bank} />
            </article>
        )
    }
   return (
      <article className='bank-account-container'>
         < AccountInfoItem headline='CBU:' text={cbu} canCopy />
         < AccountInfoItem headline='Alias:' text={alias} canCopy />
         < AccountInfoItem headline='Nombre:' text={name} />
         < AccountInfoItem headline='CUIT:' text={cuit} />
         < AccountInfoItem headline='Tipo:' text={type} />
         < AccountInfoItem headline='Banco:' text={bank} />
      </article>
   )
}
