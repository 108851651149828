import PreguntasFrecuentesContainer from '../../features/PreguntasFrecuentes/PreguntasFrecuentesContainer';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import './PreguntasFrecuentes.css';
const PreguntasFrecuentes = () => {
  return (
    <>
    <MainLayout>
      <section className='preguntasFrecuentes-section'>
        <PreguntasFrecuentesContainer/>
        </section>
        </MainLayout>
    </>
  )
}
export default PreguntasFrecuentes