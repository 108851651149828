import './TeamBubbles.css'
import BubbleItem from './BubbleItem/BubbleItem';
import { useEffect, useRef, useState } from 'react';
import TeamBubblesSkeleton from '../TeamBubblesSkeleton/TeamBubblesSkeleton';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

export default function TeamBubbles({ team, hasToChange, isLoading }) {
   const { width } = useWindowDimensions()
   const [slideState, setSlideState] = useState({ slide: 0, offset: 0 })
   const numberToSplit = width <= 768 ? 5 : 3
   const timeToChangeSlide = 6000
   const isHovered = useRef(false)

   useEffect(() => {
      if (!hasToChange) return

      const changeSlide = () => {
         if (!isHovered.current) {
            setSlideState((prevState) => {
               const nextSlide = (prevState.slide + 1) % numberToSplit
               const nextOffset = nextSlide * Math.floor(team?.length / numberToSplit)
               return { slide: nextSlide, offset: nextOffset }
            });
         }
      };

      const interval = setInterval(changeSlide, timeToChangeSlide)

      return () => {
         clearInterval(interval)
      }
   }, [hasToChange, team?.length])

   const sliceTeam = () => {
      if (hasToChange) {
         const start = slideState.offset
         const end = start + Math.floor(team?.length / numberToSplit)
         return team?.slice(start, end)
      } else {
         return team
      }
   }

   const handleHover = (isHovering) => {
      isHovered.current = isHovering
   }
   
   const teamToMap = sliceTeam()

   return (
      <div className={`teamBubbles-container ${hasToChange ? 'withMinHeight' : ''}`}>
         {isLoading || teamToMap === undefined
            ? < TeamBubblesSkeleton />
            : teamToMap?.map(person => (
               < BubbleItem key={person._id} person={person} handleHover={handleHover}
               />
            ))}
      </div>
   )
}
