import { createContext, useContext, useEffect, useState } from 'react';
import { academicUnitsTypes } from '../../utils/headerData';
import { IconChalkboard, IconMicroscope, IconSchool } from '@tabler/icons-react';
import { useAppContext } from '../AppState/AppContext';

const HeaderContext = createContext()

export const useHeaderContext = () => {
   return useContext(HeaderContext)
}

export const HeaderProvider = ({ children }) => {
   const {specialtiesInfo, openSource, isMobile} = useAppContext()
   const [toggleBurger, setToggleBurger] = useState(false)
   const [isMegaMenu, setIsMegaMenu] = useState(false)
   const [scrollValue, setScrollValue] = useState(0)

   useEffect(() => {
      const header = document.querySelector('.navbar-toggle-active')
      const isMenuActive = header

      if (isMenuActive) {
         document.body.style.overflowY = 'hidden';
      } else {
         document.body.style.overflowY = 'auto';
      }
      window.addEventListener('scroll', changeHeaderBkg)
      return () => window.removeEventListener('scroll', changeHeaderBkg)
   }, [toggleBurger])

   const changeHeaderBkg = () => {
      setScrollValue(window.scrollY)
   }

   const onMouseEnterMenu = () => {
      !isMobile &&
         setIsMegaMenu(true)
   }
   const onMouseLeaveMenu = () => {
      !isMobile &&
         setIsMegaMenu(false)
   }

   const onClickMegaMenu = () => {
      isMobile &&
         setIsMegaMenu(!isMegaMenu)
   }

   const isScrolling = scrollValue >= 1
   

   const INFO_MEGA_MENU = [
      {
         to: '/cursos',
         icon: < IconChalkboard class='mega-menu-filter-icon' />,
         description: 'Aprovechá que estas clases están abiertas y registrate',
         title: 'Clases abiertas',
         value: 'Clases abiertas',
         cardVariant: 'CardPhotoBg',
         // data: openSource?.data?.open
         data: openSource.data
      },
      {
         to: '/cursos',
         icon: < IconSchool class='mega-menu-filter-icon' />,
         description: 'Fijate las opciones que tenemos para vos',
         title: 'Unidades Académicas',
         value: 'Unidades Académicas',
         data: academicUnitsTypes,
         cardVariant: 'MegaMenuIconCard'
      },
      {
         to: '/especializaciones',
         icon: < IconMicroscope class='mega-menu-filter-icon' />,
         description: 'Mirá todas las ramas que abarcamos',
         title: 'Especialidades',
         value: 'Especialidades',
         data: specialtiesInfo.data,
         cardVariant: 'MegaMenuList'
      }
   ]

   return (
      <HeaderContext.Provider value={{
         toggleBurger, 
         setToggleBurger, 
         isMegaMenu, 
         scrollValue, 
         changeHeaderBkg, 
         onMouseEnterMenu, 
         onMouseLeaveMenu, 
         onClickMegaMenu, 
         isScrolling, 
         INFO_MEGA_MENU
      }}>
         {children}
      </HeaderContext.Provider>
   )
}