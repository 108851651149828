import React from 'react';
import './MainLayout.css'
import Header from "../../components/Header/Header";
import FooterContainer from "../../components/Footer/FooterContainer/FooterContainer";
import { HeaderProvider } from '../../context/Header/HeaderContext';
import WhatsAppFAB from '../../components/WhatsAppFAB/WhatsAppFAB';

function MainLayout({children, overflow = 'visible'}) {
    return (
        <div className="mainLayout__container" style={{overflow}}>
            <HeaderProvider>
                <Header />
            </HeaderProvider>
            <main>
                {children}
                <WhatsAppFAB/>
            </main>
            <FooterContainer/>
        </div>
    );
}

export default MainLayout;