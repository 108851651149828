import "./Specialties.css";
import SpecialtiesHero from "../features/Specialties/SpecialtiesHero/SpecialtiesHero";
import MainLayout from "../layouts/MainLayout/MainLayout";
import SpecialtiesCardsContainer from "../features/Specialties/SpecialtiesCardsContainer/SpecialtiesCardsContainer";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import DecorativeCircle from "../components/DecorativeCircle/DecorativeCircle";
import TinyDecorativeCircle from "../components/TinyDecorativeCircle/TinyDecorativeCircle";
import SuggestForm from '../features/CoursesPage/SuggestForm/SuggestForm'
import {errorHandler} from '../utils/errorHandler'
import {Skeleton} from '@mantine/core'
import CardInfo from '../components/CardInfo/CardInfo'
import {useAppContext} from '../context/AppState/AppContext'
import {calculatePrice} from "../utils/calculatePrice";

const Specialties = () => {
    const {specialtyId} = useParams()
    const [specialty, setSpecialty] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const {isUSD, officialUSDValue} = useAppContext()
    const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true);
    const url =
      process.env.REACT_APP_BACKEND_SERVER + `/info/specialty/${specialtyId}`;
    axios
      .get(url)
      .then((res) => {
        setSpecialty(res.data);
      })
      .catch((err) => {
        const errorMessage = errorHandler(err);
        setError(errorMessage);
      })
      .finally(() => setIsLoading(false));
  }, [specialtyId]);

  const openSpecialties = specialty.academicUnits?.open;
  const closedSpecialties = specialty.academicUnits?.closed;

  const renderSkeletons = (title) => (
    <SpecialtiesCardsContainer sectionTitle={title}>
      <Skeleton height={200} radius="sm" />
      <Skeleton height={200} radius="sm" />
      <Skeleton height={200} radius="sm" />
    </SpecialtiesCardsContainer>
  );

    const renderCards = (title, data, booleanValue) => (
        <SpecialtiesCardsContainer sectionTitle={title}>
            {data.map((item) => (
                <CardInfo
                    key={item._id}
                    imgUrl={item.comercialInformation.image}
                    type={item.type}
                    tag={specialty?.name}
                    title={item.comercialInformation.name.toLowerCase()}
                    price={calculatePrice(item.comercialInformation.price, officialUSDValue, isUSD, item.type)}
                    booleanValue={booleanValue}
                    classes={item.comercialInformation.classQuantity}
                    date={item.comercialInformation.initialDate}
                    rowIn={805}
                    buttonText={title === 'Inscripciones abiertas' ? 'Ver curso' : 'Ver más'}
                    buttonAction={() => navigate(`/curso/${item._id}`)}
                />
            ))}
        </SpecialtiesCardsContainer>
    )


  return (
    <MainLayout overflow="hidden">
      <section
        className="specialties-section specialties-hero-section"
        style={{ position: "relative" }}
      >
        <SpecialtiesHero
          title={specialty?.name}
          description={specialty?.description}
        />
        <DecorativeCircle
          size={100}
          top={-20}
          left={-20}
          opacity={0.5}
          shadow={1}
        />
        <DecorativeCircle
          size={150}
          bottom={-100}
          left={550}
          opacity={0.2}
          shadow={1}
        />
        <DecorativeCircle
          size={50}
          top={-20}
          left={600}
          opacity={0.2}
          shadow={4}
        />
        <DecorativeCircle
          size={100}
          top={-50}
          right={-50}
          opacity={0.2}
          shadow={2}
        />
        <TinyDecorativeCircle
          size={12}
          top={50}
          right={300}
          opacity={1}
          color={1}
        />
        <TinyDecorativeCircle
          size={12}
          top={300}
          left={70}
          opacity={0.75}
          color={1}
        />
        <TinyDecorativeCircle
          size={32}
          top={80}
          left={500}
          opacity={1}
          color={3}
        />
      </section>

      <section className="specialties-section">
        {error && !isLoading && (
          <p style={{ fontSize: "var(--fs-body)", textAlign: "center" }}>
            {error}
          </p>
        )}

        {isLoading && !error && renderSkeletons("Inscripciones abiertas")}

        {!isLoading &&
          (openSpecialties === null || openSpecialties?.length === 0) && (
            <SuggestForm />
          )}

        {!isLoading &&
          openSpecialties?.length > 0 &&
          renderCards("Inscripciones abiertas", openSpecialties, true)}
      </section>

      <section className="specialties-section">
        {isLoading && !error && renderSkeletons("Cursos finalizados")}

        {!isLoading &&
          closedSpecialties?.length > 0 &&
          renderCards("Cursos finalizados", openSpecialties, false)}
      </section>
    </MainLayout>
  );
};
export default Specialties;
