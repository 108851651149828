import './CourseCertificationContainer.css'
import SectionTitle from '../../../components/SectionTitle/SectionTitle'
import certificationImage from '../../../assets/course/certificationImage.png'

export default function CourseCertificationContainer({certificate}) {
    return (
        <div className='course-certification-container'>
            <div className='course-certification-heading'>
                <SectionTitle upText='fin del curso' downText='Certificación'/>
                <p className='course-certification-heading-description'>
                    Al finalizar tu curso en FORVET, recibirás un
                    certificado respaldado por destacadas instituciones veterinarias. Este certificado valida tus
                    conocimientos en el campo y es reconocido por la comunidad.
                </p>
            </div>
            <div className='certification-image-container'>
                <img className='certification-image' src={certificate ?? certificationImage}
                      alt='foto de certificado de FORVET'/>
            </div>
        </div>
    )
}
