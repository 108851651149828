import './SpecialtiesCardsContainer.css'

export default function SpecialtiesCardsContainer({
   sectionTitle,
   children   
}) {
      return (
      <div className="specialties-cards-container">
         {sectionTitle &&
            <div className='specialties-cards-heading'>
               <h2>{sectionTitle}</h2>
            </div>
         }
         <section className='cardsToMap-container'>
            {children}
         </section>
      </div>
   )
}
