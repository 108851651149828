import { IconBallpen, IconBooks, IconBrandYoutube, IconMicroscope, IconSchool } from "@tabler/icons-react"


export const academicUnitsTypes = [
   { text: 'curso', to: '/', icon: < IconBallpen class='mega-menu-iconCard-icon' /> },
   { text: 'posgrado', to: '/', icon: < IconMicroscope class='mega-menu-iconCard-icon' /> },
   { text: 'diplomado', to: '/', icon: <  IconSchool class='mega-menu-iconCard-icon' /> },
   { text: 'masterclass', to: '/', icon: < IconBooks class='mega-menu-iconCard-icon' /> },
   { text: 'webinar', to: '/', icon: < IconBrandYoutube class='mega-menu-iconCard-icon' /> },
]
