import { IconBug, IconButterfly, IconCactus, IconCherry, IconChristmasTree, IconFlower, IconCat, IconDeer, IconDogBowl, IconDog, IconFish, IconHorse, IconPawFilled, IconPigMoney} from '@tabler/icons-react';
export const naturalIcons = [
   < IconDog className='mega-menu-list-icon' />,
   < IconBug className='mega-menu-list-icon' />,
   < IconButterfly className='mega-menu-list-icon' />,
   < IconCat className='mega-menu-list-icon' />,
   < IconCactus className='mega-menu-list-icon' />,
   < IconCherry className='mega-menu-list-icon' />,
   < IconChristmasTree className='mega-menu-list-icon' />,
   < IconFlower className='mega-menu-list-icon' />,
   < IconDeer className='mega-menu-list-icon' />,
   < IconDogBowl className='mega-menu-list-icon' />,
   < IconDog className='mega-menu-list-icon' />,
   < IconFish className='mega-menu-list-icon' />,
   < IconHorse className='mega-menu-list-icon' />,
   < IconPawFilled className='mega-menu-list-icon' />,
   < IconPigMoney className='mega-menu-list-icon' />,
   < IconCat className='mega-menu-list-icon' />,
]