import React from 'react';
import './ImageOverShape.css';

function generateRandomNumber() {
    return Math.floor(Math.random() * 6) + 1;
}

/**
 * This component is used to render an image over a random rectangular shape.
 * @param imageUrl - The url of the image to be rendered.
 * @param height - The height of the container.
 * @param straightSide - The side of the rectangle that will be straight. It can be 'bottom', 'left' or 'right'.
 * @returns {JSX.Element}
 * @example <ImageOverShape imageUrl={ImageURL} height={300} straightSide={'left'}/>
 */
function ImageOverShape({imageUrl, height = 200, straightSide = 'bottom', left = 'unset', right = 'unset', backgroundColor = '#70B3D2', square}) {
    const randomNumber = generateRandomNumber();
    

    return (
        <div className="imageOverShape-container" style={{height: `${height}px`}}>
            <div className={`imageOverShape-shape imageOverShape-rectangle-${straightSide}-${randomNumber} ${square ? 'imageOverShape-rectangle-square' : ''}`} style={{backgroundColor}} />
            <img className="imageOverShape-image" src={imageUrl} alt="course_hero" style={{height: `${height*1.25}px`, left, right}} />
        </div>
    );
}

export default ImageOverShape;