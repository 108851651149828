import { Carousel } from '@mantine/carousel'
import Autoplay from 'embla-carousel-autoplay';
import React, { useRef } from 'react'
import './AvalCarouselContainer.css'
import AvalItem from '../AvalItem/AvalItem';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export default function AvalCarouselContainer({avals}) {
   const timeToAutoplay = 3500
   const autoplay = useRef(Autoplay({ delay: timeToAutoplay }));
   const {width} = useWindowDimensions();
   const isMobile = width <= 768;
   return (
      <>
        {isMobile ? (
          <Carousel
            slideSize={{ base: '33%', sm: '20%' }}
            slideGap={'md'}
            loop
            align="start"
            slidesToScroll={3}
            plugins={[autoplay.current]}
            withControls={false}
            stopOnInteraction={false}
          >
            {avals?.map((item) => (
              <Carousel.Slide key={item._id}>
                <AvalItem item={item} />
              </Carousel.Slide>
            ))}
          </Carousel>
        ) : (
          <Carousel
            slideSize={{ base: '33%', sm: '20%' }}
            slideGap={'md'}
            loop
            align="start"
            slidesToScroll={5}
            plugins={[autoplay.current]}
            withControls={false}
          >
            {avals?.map((item) => (
              <Carousel.Slide key={item._id}>
                <AvalItem item={item} />
              </Carousel.Slide>
            ))}
          </Carousel>
        )}
      </>
    );
  }

