import SpecialtiesHero from '../../Specialties/SpecialtiesHero/SpecialtiesHero'
import image from '../../../assets/institutional/vet_dog2.png'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

const description = `"Soñamos con ser la empresa líder en la formación integral de profesionales elegida por su 
excelencia académica, calidad humana y compromiso social. 
En un mundo donde podamos entender que solo desde la formación personal, podremos 
llegar a ser buenos profesionales y que solo desde una buena profesión podremos generar 
buenos negocios.
Buscamos generar lazos entre comunidades profesionales, 
abiertas a las necesidades de la sociedad en su conjunto."`

export default function VisionSection() {
   const {width} = useWindowDimensions()
   const isMobile = width <= 768
  return (
      < SpecialtiesHero firstImage  height={isMobile ? 200 : 250} title='Visión' imageUrl={image} description={description} square right='0' />
  )
}
