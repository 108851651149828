import './TransferBankHero.css'
import { Button } from "@mantine/core";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import { useNavigate } from "react-router-dom";
import { IconArrowLeft } from "@tabler/icons-react";
import TransferBankCart from '../TransferBankCart/TransferBankCart';

export default function TransferBankHero() {
   const navigate = useNavigate()

   const goBack = (event) => {
      event.preventDefault()
      navigate(-1)
   }

   return (
      <div className="transferBankHero-container">
         <SectionTitle SectionTitle downText="Vamos a terminar tu pago" upText="transferencia bancaria" >

            <Button style={{order: -1}} onClick={goBack} variant="outline" mb={20} >
               < IconArrowLeft style={{marginRight: '8px'}} />
               Volver atrás
            </Button>
         
         </SectionTitle>
         <TransferBankCart/>
      </div>
   )
}
