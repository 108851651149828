import React, {useEffect, useState} from 'react';
import './CreateAcademicUnitForm.css';
import {useForm} from "@mantine/form";
import {Button, Grid, Loader, Modal, MultiSelect, NumberInput, Select, Textarea, TextInput} from "@mantine/core";
import axios from "axios";
import CreateSpecialtyForm from "../CreateSpecialtyForm/CreateSpecialtyForm";
import CreateUserForm from "../CreateUserForm/CreateUserForm";
import CreateModuleForm from "../CreateModuleForm/CreateModuleForm";
import CreateLessonForm from "../CreateLessonForm/CreateLessonForm";
import showNotification from "../../../utils/showNotification";
import CreateSubscriptionPlanForm from "../CreateSubscriptionPlanForm/CreateSubscriptionPlanForm";
import {useNavigate} from "react-router";

function CreateAcademicUnitForm(props) {
    const {isAuth} = props;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(null);
    const [createSpecialtyModal, setCreateSpecialtyModal] = useState(false);
    const [createUserModal, setCreateUserModal] = useState(false);
    const [createModuleModal, setCreateModuleModal] = useState(false);
    const [createLessonModal, setCreateLessonModal] = useState(false);
    const [createSubscriptionModal, setCreateSubscriptionModal] = useState(false);
    const [updateData, setUpdateData] = useState(false);
    const form = useForm({
        initialValues: {
            specialties: [],
            //Users
            director: [],
            codirector: [],
            coordinator: "",
            teachers: [],
            //Academic Unit
            selectedModule: "",
            modules: {},
            selectedLesson: "",
            //Commercial information
            type: "",
            name: "",
            description: "",
            initialDate: "", //Ej: 7 de Febrero 2024 [String]
            endDate: "", //Ej: 7 de Febrero 2024 [String]
            initBreak: "", //Ej: 7 de Febrero 2024 [String] || null
            endBreak: "",  //Ej: 7 de Febrero 2024 [String] || null
            classQuantity: 1,
            price: {
                usd: 0,
                ars: 0
            },
            image: "", //URL
            classDays: "", //Ej: Lunes y Jueves [String]
            classHour: "", //Ej: 19:00 [String]
            closeCampusDate: "", //Ej: 7 de Febrero 2024 [String]
            subscriptionPlans: []
        },
        validate: {},
    });

    const handleSubmit = async (values) => {
        console.log(values)
        setLoading(true)

        //TODO: Fix fields:
        //     -initBreak, endBreak
        const data = {
            data: {...values}
        }

        axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/admin/createAcademicUnit`, data)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    showNotification({
                        color: 'green',
                        status: 'success',
                        title: "Unidad académica creada",
                        message: `La unidad académica ${res.data.name} ha sido creada exitosamente`,
                    });
                    // form.reset();
                } else {
                    showNotification({
                        color: "red",
                        status: "error",
                        title: "Error",
                        message: `Error al crear la unidad académica`,
                    });
                }
            })
            .catch((err) => {
                console.log(err)
                const error = err.message || err.response.data.message || err || "Error al crear la unidad académica";
                showNotification({
                    color: "red",
                    status: "error",
                    title: "Error",
                    message: `Error al crear la unidad académica: ${error}`,
                });
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const init = async () => {
        // Specialties
        const specialtiesInfo = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER}/info/specialties/`)
        const specialtiesData = specialtiesInfo.data.map((specialty) => {
            return {
                value: specialty._id,
                label: specialty.name
            }
        })
        // Users
        const usersInfo = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER}/admin/users`)
        const usersData = usersInfo.data.map((_user) => {
            return {
                value: _user._id,
                label: `${_user.name} ${_user.lastname}`
            }
        })
        // Modules
        const modulesInfo = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER}/admin/modules`)
        const modulesData = modulesInfo.data.map((_module) => {
            return {
                value: _module._id,
                label: _module.realName ?? _module.name
            }
        })
        // Lessons
        const lessonsInfo = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER}/admin/lessons`)
        const lessonsData = lessonsInfo.data.map((_lesson) => {
            return {
                value: _lesson._id,
                label: _lesson.name
            }
        })

        // Set form data
        setFormData({
            specialties: specialtiesData,
            users: usersData,
            modules: modulesData,
            lessons: lessonsData
        })
    }

    const removeSubscriptionPlan = (index) => {
        const newSubscriptionPlans = form.values.subscriptionPlans.filter((_, i) => i !== index)
        form.setFieldValue("subscriptionPlans", newSubscriptionPlans)
    }

    useEffect(() => {
        if (isAuth) {
            init()
        } else {
            navigate("/admin")
        }
    }, [isAuth, updateData]);

    if (!formData) return <Loader/>;
    return (
        <>
            <form
                onSubmit={
                    form.onSubmit((values) => handleSubmit(values))
                }
                className="createAUform__form"
            >
                <h3 className="createAUform__title">Crear unidad académica</h3>

                <div className="createAUform__block">
                    <h3 className="createAUform__subtitle">Información comercial</h3>
                    <Grid style={{width: "100%"}}>
                        <Grid.Col span={12}>
                            <Select
                                label="Tipo de la unidad académica"
                                {...form.getInputProps('type')}
                                data={[
                                    {value: "Curso", label: "Curso"},
                                    {value: "Masterclass", label: "Masterclass"},
                                    {value: "Diplomado", label: "Diplomado"},
                                    {value: "Posgrado", label: "Posgrado"},
                                    {value: "Webinar", label: "Webinar"},
                                    {value: "Taller", label: "Taller"},
                                ]}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                label="Nombre de la unidad académica"
                                {...form.getInputProps('name')}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                label="URL de imagen de la unidad académica"
                                {...form.getInputProps('image')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Textarea
                                label="Descripción de la unidad académica"
                                {...form.getInputProps('description')}
                                autosize
                                minRows={2}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                placeholder="10 de Enero 2024"
                                label="Fecha de inicio"
                                {...form.getInputProps('initialDate')}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                placeholder="20 de Enero 2024"
                                label="Fecha de finalización"
                                {...form.getInputProps('endDate')}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                placeholder="14 de Enero 2024"
                                label="Inicio de receso"
                                {...form.getInputProps('initBreak')}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                placeholder="16 de Enero 2024"
                                label="Fin de receso"
                                {...form.getInputProps('endBreak')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                placeholder="30 de Enero 2024"
                                label="Fecha de cierre de campus"
                                {...form.getInputProps('closeCampusDate')}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                placeholder="Lunes y Jueves"
                                label="Días de clase"
                                {...form.getInputProps('classDays')}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                placeholder="19:00"
                                label="Hora de clase"
                                {...form.getInputProps('classHour')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <NumberInput
                                label="Cantidad de clases"
                                {...form.getInputProps('classQuantity')}
                                min={1}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <NumberInput
                                label="Precio en USD"
                                {...form.getInputProps('price.usd')}
                                min={0}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <NumberInput
                                label="Precio en ARS"
                                {...form.getInputProps('price.ars')}
                                min={0}
                            />
                        </Grid.Col>
                    </Grid>
                </div>
                <div className="createAUform__block">
                    <h3 className="createAUform__subtitle">Planes de suscripción (Cuotas)</h3>
                    <Grid style={{width: "100%"}}>
                        <Grid.Col span={12}>
                            {form.values.subscriptionPlans.length === 0 && (
                                <p className="createAUform__text">No hay planes de suscripción creados</p>
                            )}
                            {form.values.subscriptionPlans.map((subscription, index) => (
                                <div key={index} className="createAUform__subscription__container" style={{display: "flex", justifyContent: "space-between"}}>
                                    <p className="createAUform__text">
                                        Plan de suscripción {index + 1}: {subscription.total_cycles} cuotas de ${subscription.amount_per_cycle}
                                    </p>
                                    <Button
                                        type="button"
                                        variant="outline"
                                        onClick={() => removeSubscriptionPlan(index)}
                                    >
                                        Eliminar
                                    </Button>
                                </div>
                            ))}
                        </Grid.Col>
                        <Grid.Col span={12} align="center">
                            <Button
                                className="createAUform__button"
                                style={{alignSelf: "center"}}
                                onClick={() => setCreateSubscriptionModal(true)}
                            >
                                Crear plan de suscripción
                            </Button>
                        </Grid.Col>
                    </Grid>
                </div>
                <div className="createAUform__block">
                    <h3 className="createAUform__subtitle">Especialidad</h3>
                    <Grid style={{width: "100%"}}>
                        <Grid.Col span={12}>
                            <MultiSelect
                                label="Especialidades"
                                {...form.getInputProps('specialties')}
                                data={formData.specialties}
                                searchable
                            />
                        </Grid.Col>
                    </Grid>

                    <Button
                        className="createAUform__button"
                        onClick={() => setCreateSpecialtyModal(true)}
                    >
                        Crear especialidad
                    </Button>
                </div>

                <div className="createAUform__block">
                    <h3 className="createAUform__subtitle">Usuarios</h3>
                    <Grid style={{width: "100%"}}>
                        <Grid.Col span={6}>
                            <Select
                                label="Coordinador"
                                {...form.getInputProps('coordinator')}
                                data={formData.users}
                                searchable
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <MultiSelect
                                label="Directores"
                                {...form.getInputProps('director')}
                                data={formData.users}
                                searchable
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <MultiSelect
                                label="Co-directores"
                                {...form.getInputProps('codirector')}
                                data={formData.users}
                                searchable
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <MultiSelect
                                label="Profesores"
                                {...form.getInputProps('teachers')}
                                data={formData.users}
                                searchable
                            />
                        </Grid.Col>
                    </Grid>

                    <Button
                        className="createAUform__button"
                        onClick={() => setCreateUserModal(true)}
                    >
                        Crear usuario
                    </Button>
                </div>

                <div className="createAUform__block">
                    <h3 className="createAUform__subtitle">Módulos y clases</h3>
                    <Grid style={{width: "100%"}}>
                        <Grid.Col span={9}>
                            <Select
                                label="Modulos"
                                description="Selecciona el módulo que quieres agregar, luego presiona el botón 'Agregar módulo'"
                                {...form.getInputProps('selectedModule')}
                                data={formData.modules}
                                searchable
                            />
                        </Grid.Col>

                        <Grid.Col span={3} justify="center" align="center">
                            <Button
                                className="createAUform__button"
                                onClick={() => {
                                    form.setFieldValue(`modules.${form.values.selectedModule}`, [])
                                }}
                            >
                                Agregar módulo
                            </Button>
                        </Grid.Col>

                        <div className="createAUform__module__wrapper">
                            {Object.keys(form.values.modules).map((_module) => {
                                const moduleData = formData.modules.find((module) => module.value === _module)
                                return (
                                    <Grid.Col span={12}>
                                        <div className="createAUform__module__container">
                                            <h3 className="createAUform__subtitle">{moduleData.label}</h3>

                                            <Grid style={{width: "100%"}}>
                                                <Grid.Col span={9}>
                                                    <Select
                                                        label="Clases"
                                                        description="Selecciona la clase que quieres agregar, luego presiona el botón 'Agregar clase'"
                                                        {...form.getInputProps('selectedLesson')}
                                                        data={formData.lessons}
                                                        searchable
                                                    />
                                                </Grid.Col>

                                                <Grid.Col span={3} justify="center" align="center">
                                                    <Button
                                                        className="createAUform__button"
                                                        onClick={() => {
                                                            form.setFieldValue(`modules.${_module}`, [...form.values.modules[_module], form.values.selectedLesson])
                                                        }}
                                                    >
                                                        Agregar clase
                                                    </Button>
                                                </Grid.Col>
                                            </Grid>

                                            <div className="createAUform__lesson__wrapper">
                                                {form.values.modules[_module].map((lesson) => {
                                                    const lessonData = formData.lessons.find((_lesson) => _lesson.value === lesson)
                                                    return (
                                                        <div className="createAUform__lesson__container">
                                                            <Grid style={{width: "100%"}}>
                                                                <Grid.Col span={9} justify="center" align="center">
                                                                    <h4 className="createAUform__subtitle">{lessonData.label}</h4>
                                                                </Grid.Col>

                                                                <Grid.Col span={3} justify="center" align="center">
                                                                    <Button
                                                                        variant="outline"
                                                                        size="xs"
                                                                        onClick={() => {
                                                                            form.setFieldValue(`modules.${_module}`, form.values.modules[_module].filter((_lesson) => _lesson !== lesson))
                                                                        }}
                                                                    >
                                                                        Eliminar clase
                                                                    </Button>
                                                                </Grid.Col>
                                                            </Grid>

                                                        </div>
                                                    )
                                                })}
                                            </div>

                                            <Button
                                                className="createAUform__button"
                                                variant="outline"
                                                size="xs"
                                                onClick={() => {
                                                    form.setFieldValue(`modules`, Object.keys(form.values.modules).reduce((acc, _module) => {
                                                        if (_module !== moduleData.value) {
                                                            acc[_module] = form.values.modules[_module]
                                                        }
                                                        return acc
                                                    }, {}))
                                                }}
                                            >
                                                Eliminar módulo
                                            </Button>
                                        </div>
                                    </Grid.Col>
                                )
                            })}
                        </div>
                    </Grid>

                    <Grid>
                        <Grid.Col span={6}>
                            <Button
                                className="createAUform__button"
                                onClick={() => setCreateModuleModal(true)}
                            >
                                Crear módulo
                            </Button>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Button
                                className="createAUform__button"
                                onClick={() => setCreateLessonModal(true)}
                            >
                                Crear clase
                            </Button>
                        </Grid.Col>
                    </Grid>
                </div>


                <Button color="secondaryColor"
                        type="submit"
                        loading={loading}
                >
                    Crear unidad académica
                </Button>
            </form>
            {/*MODALS*/}
            <Modal opened={createSubscriptionModal} onClose={() => setCreateSubscriptionModal(false)}>
                <CreateSubscriptionPlanForm
                    formHandler={form}
                    setOpen={setCreateSubscriptionModal}
                />
            </Modal>
            <Modal opened={createSpecialtyModal} onClose={() => setCreateSpecialtyModal(false)}>
                <CreateSpecialtyForm
                    updateData={updateData}
                    setUpdateData={setUpdateData}
                    setOpen={setCreateSpecialtyModal}
                />
            </Modal>
            <Modal opened={createUserModal} onClose={() => setCreateUserModal(false)}>
                <CreateUserForm
                    updateData={updateData}
                    setUpdateData={setUpdateData}
                    setOpen={setCreateUserModal}
                />
            </Modal>
            <Modal opened={createModuleModal} onClose={() => setCreateModuleModal(false)}>
                <CreateModuleForm
                    updateData={updateData}
                    setUpdateData={setUpdateData}
                    setOpen={setCreateModuleModal}
                />
            </Modal>
            <Modal opened={createLessonModal} onClose={() => setCreateLessonModal(false)}>
                <CreateLessonForm
                    updateData={updateData}
                    setUpdateData={setUpdateData}
                    setOpen={setCreateLessonModal}
                    usersData={formData.users}
                />
            </Modal>
        </>
    );
}

export default CreateAcademicUnitForm;