import React from 'react';
import './TinyDecorativeCircle.css';
import {useAppContext} from "../../context/AppState/AppContext";

function TinyDecorativeCircle(props) {
    const {size, opacity, color = 1, top, left, right, bottom, hideInMobile} = props;
    const { isMobile } = useAppContext()
    const style = {
        width: `${size}px`,
        height: `${size}px`,
        borderWidth: `${size}px`,
        opacity: opacity,
    };

    if (top !== undefined) {
        style.top = `${top}px`;
    }

    if (left !== undefined) {
        style.left = `${left}px`;
    }

    if (right !== undefined) {
        style.right = `${right}px`;
    }

    if (bottom !== undefined) {
        style.bottom = `${bottom}px`;
    }

    if (hideInMobile !== undefined && isMobile) {
        style.display = `none`;
    }

    return (
        <div
            className={`tinyDecorativeCircle tinyDecorativeCircle__color-${color}`}
            style={style}
        ></div>
    );
}

export default TinyDecorativeCircle;