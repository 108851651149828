import axios from "axios"
import { useEffect, useState } from "react"
import { useIsMount } from "./useIsMount"

// 👉 UseEffect => Si es el primer render, pregunta si ya esta el item en localStorage:
// - Si existe: 
// 🔍 Chequea si la fecha en la que se acepto no haya expirado (7 dias).
// ✅ Si no expiro, setea isUSD en el valor correspondiente y retorna.
// ❌ Si expiro, remueve el item de local storage y sigue el camino para abrir el modal 
// 👉 getUserLocation => es una funcion para que la utilice el boton 'aceptar' del modal
// 👉 Al hacer clik en "aceptar":
// - Primer GET para obtener la IP del usuario
// - Segundo GET anidado para a partir del IP obtenido
// - Con este llamado, se obtiene el codigo del pais: EJ: 'AR', 'US'
// - Se define el valor de isUSD
// - Setea el item en localStorage
// ❌ Si hay un error, no se setea nada solo se sierra el modal

// ⛔ El navegador puede bloquear la peticion automaticamente. ⛔


// options: ipify - ipapi - ipinfo - bigdatacloud
const IPIFY_URL = 'https://api.ipify.org?format=json'
// const IP_API_URL = 'https://ipapi.co/json/'
const IP_INFO_URL = 'https://ipinfo.io/'

const ARS_STRING = 'AR'
const SEVEN_DAYS_IN_MS = 604800000

export default function useUserLocation() {
   const [isUSD, setIsUSD] = useState(true)
   const [permissionModal, setPermissionModal] = useState({ open: false, error: null })
   const isMount = useIsMount()


   useEffect(() => {
      if (!isMount) return

      const isLocalStorage = JSON.parse(window.localStorage.getItem('FVW-currency'))

      if (!isLocalStorage) {
         getUserLocation()
         return;
      }

      const { currency, dateSaved, dateExpire } = isLocalStorage

      if (dateSaved < dateExpire) return setIsUSD(currency)

      getUserLocation()

   }, [isMount])

   const getUserLocation = async () => {
      try {
         const { data: ipResponse } = await axios.get(IPIFY_URL)
         const { ip } = ipResponse

         const { data: countryResponse } = await axios.get(`${IP_INFO_URL}${ip}/json?token=${process.env.REACT_APP_IP_API_TOKEN}`)
         const { country } = countryResponse

         setIsUSD(country !== ARS_STRING)

         const dateSaved = new Date().getTime()
         const currencyLocalStorage = {
            currency: country !== ARS_STRING,
            dateSaved,
            dateExpire: dateSaved + SEVEN_DAYS_IN_MS
         }

         window.localStorage.setItem('FVW-currency', JSON.stringify(currencyLocalStorage))
         // setPermissionModal({ open: false, error: null })

      } catch (err) {
         console.log(err)
         // err?.code === "ERR_NETWORK" && setPermissionModal({ open: true, error: 'Puede que tu navegador haya bloqueado esta acción. Para resolverlo, ve a "permisos".' })
         // setPermissionModal({open: true, error: 'Puede que tu navegador haya bloqueado esta acción. Para resolverlo, ve a "permisos".'})
      }
   }

   return {
      getUserLocation,
      permissionModal,
      setPermissionModal,
      isUSD
   }
}


