import {useState} from 'react';
import "./Navbar.css";
import {
    IconBooks,
    IconDiscount,
} from '@tabler/icons-react';

const data = [
    {link: '/admin/ua', name: "academicUnitForm", label: 'Unidad Académica', icon: IconBooks},
    {link: '/admin/descuentos', name: "discountManager",  label: 'Descuentos', icon: IconDiscount},
];

export function Navbar(props) {
    const {setActiveComponent} = props;
    const links = data.map((item) => (
        <a
            className="navbar__link"
            href={item.link}
            key={item.label}
            onClick={(event) => {
                event.preventDefault();
                setActiveComponent(item.name);
            }}
        >
            <item.icon className="navbar__linkIcon" stroke={1.5}/>
            <span>{item.label}</span>
        </a>
    ));

    return (
        <div>
            <nav className="navbar__navbar">
                <div className="navbar__navbarMain">
                    {links}
                </div>
            </nav>
        </div>
    );
}