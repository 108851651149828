import './AvalItem.css'
import { Link } from 'react-router-dom'
import CircleBlue from "../../../assets/circle_blue.png"
import CirclePink from "../../../assets/circle_pink.png"

export default function AvalItem({ item }) {
   return (
       <div className="aval-image-wrapper">
           <img className="aval-image-circle-blue" src={CircleBlue} alt="circle_blue"/>
           <img className="aval-image-circle-pink" src={CirclePink} alt="circle_pink"/>
          <Link target='_blank' to={item.web} className='aval-image-container'>
             < img className='aval-image' src={item.image} alt={item.name} />
          </Link>
       </div>
   )
}
