import { notifications } from '@mantine/notifications';
import { IconCircleCheck, IconAlertCircle, IconCircleX } from '@tabler/icons-react';

function showNotification({
   id = Math.random().toString(36).substr(2, 8),
   autoClose = 5000,
   // autoClose = 100000,

   color = 'red',
   icon = null,
   style = {},
   loading = false,
   title,
   message,
   status = null,
   notificationMaxHeight = 300,
   className = 'mantine-notifications--container',
}) {

   const notificationProps = {
      id,
      autoClose,
      color,
      style,
      loading,
      title,
      message,
      notificationMaxHeight,
      className,
   };

   if (icon !== null) {
      notificationProps.icon = icon;
   } else if (status !== null) {
      switch (status) {
         case "success":
            notificationProps.icon = <IconCircleCheck style={{ width: "24px", height: "24px", stroke: "white" }} />;
            break;
         case "warning":
            notificationProps.icon = <IconAlertCircle style={{ width: "24px", height: "24px", stroke: "white" }} />;
            break;
         case "error":
            notificationProps.icon = <IconCircleX style={{ width: "24px", height: "24px", stroke: "white" }} />;
            break;
      }
   }

   notifications.show(notificationProps);
}

export default showNotification;