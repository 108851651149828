import { Avatar } from "@mantine/core";
import "./CourseReviewsCard.css";

const CourseReviewsCard = ({reviews}) => {
  return (
    <div className="courseReviewsCard-container">
      {reviews.length === 0 && (
          <p className="courseReviewsCard-no-reviews">
            Aún no hay valoraciones
          </p>
      )}
      {reviews.map((testimonies) => (
        <div className="courseReviewscard-card-content-container">
          <div className="courseReviewscard-content-container-div">
            <div className="courseReviewscard-avatar-and-text-container-div">
              <Avatar
                src={testimonies.profilePicture}
                alt={testimonies.name}
                radius="xl"
                size="lg"
              />
              <div className="courseReviewscard-personal-info-div-container">
                <h5 className="courseReviewscard-personal-info-h5-title">
                  {testimonies.name}
                </h5>
                <p className="courseReviewscard-date-p">
                  {testimonies.date}
                </p>
              </div>
            </div>

            <div className="courseReviewsCard-typography-p-custom">
              <p>{testimonies.review}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default CourseReviewsCard;
