import React, {useEffect, useState} from 'react';
import "./LoginForm.css";
import {useForm} from "@mantine/form";
import axios from "axios";
import {Button, TextInput} from "@mantine/core";
import {useNavigate} from "react-router-dom";

function LoginForm(props) {
    const {setIsAuth} = props;
    const navigate = useNavigate ();
    const [retriesCount, setRetriesCount] = useState(0);
    const form = useForm({
        initialValues: {
            email: '',
            password: '',
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Email inválido'),
            password: (value) => (value.length < 4 ? 'Contraseña demasiado corta' : null),
        },
    });

    const handleSubmit = async (values) => {
        const data = {
            email: values.email,
            password: values.password,
        }
        axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/admin/login`, data)
            .then((res) => {
                if (res.status === 200) {
                    setIsAuth(true)
                }
            })
            .catch(() => {
                setRetriesCount(retriesCount + 1)
            })

    }
    useEffect(() => {
        if (retriesCount > 1) {
            navigate('/');
        }
    }, [retriesCount]);

    return (
        <>
            <h2 className="admin__form__title">Login de Administrador</h2>

            <form
                onSubmit={
                    form.onSubmit((values) => handleSubmit(values))
                }
                className="admin__form__form"
            >
                <div className="admin__form__block">
                    <TextInput
                        withAsterisk
                        label="Email"
                        placeholder="your@email.com"
                        {...form.getInputProps('email')}
                    />
                </div>
                <div className="admin__form__block">
                    <TextInput
                        withAsterisk
                        label="Contraseña"
                        placeholder=""
                        type="password"
                        {...form.getInputProps('password')}
                    />
                </div>
                <Button color="secondaryColor" type="submit">Iniciar sesión</Button>
            </form>
        </>
    );
}

export default LoginForm;