import { Accordion } from "@mantine/core";
import "./CourseScheduleAccordion.css";
import { IconSchool } from "@tabler/icons-react";

const CourseScheduleAccordion = ({modules}) => {

  const items = modules.map((module, index) => (
    <Accordion.Item key={index} value={module.name}>
      <Accordion.Control
        icon={<IconSchool className="iconsSchool-accordion-component" />}
      >
        <h4 className="accordion-itemTitle-classname">{module.name}</h4>
      </Accordion.Control>
      <Accordion.Panel>{module.description}</Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <div className="courseScheduleAccordion-father-div">
      <Accordion
        classNames={{
          control: "accordion-control-classname",
          itemTitle: "accordion-itemTitle-classname",
          content: "accordion-content-classname",
        }}
        transitionDuration={1000}
        variant="separated"
      >
        {items}
      </Accordion>
    </div>
  );
};
export default CourseScheduleAccordion;
