import ImageOverShape from "../../../components/ImageOverShape/ImageOverShape";
import "./CoursesHero.css";

const HERO_DATA = [
  {
    title: "Encontrá el Camino hacia el Éxito Profesional con Nuestros Cursos",
    description: "Diseñados para impulsar tu excelencia académica, nuestros programas te brindarán las herramientas necesarias para destacarte en la profesión. Encuentra, con nosotros, tu camino hacia la especialización.",
    imageUrl: "https://i.imgur.com/nDLxd7p.png",
  },
];

const CoursesHero = () => {
  return (
    <div className="coursesLandingHero-father-div-container">
      {HERO_DATA.map((heroInfo, index) => (
        <div key={index} className="coursesLandingHero-content-wrapper">
          <div className="coursesLandingHero-image-container">
            <ImageOverShape
              straightSide="left"
              height={150}
              imageUrl={heroInfo.imageUrl}
            />
          </div>
          <div className="coursesLandingHero-all-content-container-div">
            <div className="coursesLandingHero-title-and-description-div">
              <div className="coursesLandingHero-title-and-description-content-div">
                <h2 className="coursesLandingHero-title-h1-styling">
                  {heroInfo.title}
                </h2>
                <p className="coursesLandingHero-paragraph-p-styling">
                  {heroInfo.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CoursesHero;
