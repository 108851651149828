const hundredRounder = (number, roundType) => {
    if (roundType === "down") {
        return Math.floor(number / 100) * 100;
    } else if (roundType === "up") {
        return Math.ceil(number / 100) * 100;
    } else {
        return number;
    }
}

export {hundredRounder};