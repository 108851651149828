import ImageOverShape from "../../../components/ImageOverShape/ImageOverShape";
import { useAppContext } from "../../../context/AppState/AppContext";
import "./CourseHero.css";

const CourseHero = (props) => {
   const {width, isMobile} = useAppContext()

    const {title, description, imageUrl, isOpen = true} = props;

    return (
        <div className="courseHero-div-father-container">
            <div className="courseHero-div-father-wrapper">
                <div className="courseHero-all-content-container-div">
                    <div className="courseHero-title-and-description-div">
                        <h2 className="courseHero-title-h2-styling">
                            {title}
                        </h2>
                        <p className="courseHero-paragraph-p-styling">
                            {description}
                        </p>
                        {!isOpen && !isMobile && <span className="sectionTitle-upText">¡Inscripciones cerradas!</span>}
                    </div>
                    <div className="courseHero-image-container">
                        <ImageOverShape right={width > 1200 ? 'unset' : '0' } imageUrl={imageUrl} height={180}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseHero;
