import './FooterContainer.css'
import petImage from '../../../assets/footer/cat_bg-removebg-preview.png'
// import petImage from '../../../assets/footer/cat_dog.png'
// import petImage from '../../../assets/footer/doggroup.png'
// import petImage from '../../../assets/footer/dogdown.png'

import FooterInfo from '../FooterInfo/FooterInfo'
import FooterLegend from '../FooterLegend/FooterLegend'

export default function FooterContainer() {
   return (
      <footer className='footer'>
         < FooterLegend />
         < FooterInfo />
         < img className='footer-bkgImage' src={petImage} alt='foto de gato' />
      </footer>
   )
}
