import './WhatsAppFAB.css';
import whatsappIcon from '../../assets/footer/whatsapp.svg';
import {initFbPixel} from "../../utils/initFbPixel";
import ReactPixel from "react-facebook-pixel";
const WhatsAppFAB = () => {
  return (
    <div>
        <a onClick={() => {
            initFbPixel();
            ReactPixel.track('Contact');
        }} href="https://wa.me/+5493512086545?text=¡Hola!%20Buenos%20días,%20tengo%20una%20consulta..." className="whatsAppFAB-float" target="_blank">
         <img className="whatsAppFAB-my-float" alt="Wapp" src={whatsappIcon} />
        </a>
    </div>
  )
}
export default WhatsAppFAB