import './AboutUsHero.css'
import SpecialtiesHero from '../../Specialties/SpecialtiesHero/SpecialtiesHero'

import heroImg from '../../../assets/institutional/vet_catImg.png'

const heroDescription = '"Somos una comunidad apasionada de expertos veterinarios que comparten su vasta experiencia para formar especialistas de alta calidad, donde el aprendizaje surge de la colaboración.”'

export default function AboutUsHero() {

    return (
        <SpecialtiesHero imageUrl={heroImg} height={250} right='10%' title='SOBRE NOSOTROS'
                         description={heroDescription}/>
    )
}
