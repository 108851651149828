// import { Link } from 'react-router-dom'
import './BubbleOverlay.css'
// import linkedinIcon from '../../../../../assets/team/linkedin.svg'
// import facebookIcon from '../../../../../assets/team/facebook.svg'
// import mailIcon from '../../../../../assets/team/mail.svg'

export default function BubbleOverlay({ person, bubbleSize }) {
   return (
      <div className='bubble-overlay-container' style={{ height: bubbleSize, width: bubbleSize }}>
         <div className='bubble-overlay-heading'>
            <h5>{person.name} {person.lastname}</h5>
         </div>
         {/* <div className='bubble-overlay-socialMedia-container'>
            <Link target="_blank" to='www.google.com' className='overlay-link-container'>
               < img src={facebookIcon} alt='icon' />
            </Link>
            <Link target="_blank" to='www.google.com' className='overlay-link-container'>
               < img src={linkedinIcon} alt='icon' />
            </Link>
            <Link target="_blank" to='www.google.com' className='overlay-link-container'>
               < img src={mailIcon} alt='icon' />
            </Link>
         </div> */}
      </div>
   )
}
