import SpecialtiesHero from "../../Specialties/SpecialtiesHero/SpecialtiesHero";
import image from "../../../assets/institutional/vet_dogImg_shape.png";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const description = `"FORVET “Formación Integral Veterinaria”
brinda a médicos veterinarios graduados, de habla hispana, 
una comunidad de formación  e intercambio profesional entre colegas  
y docentes de alto prestigio, cada uno en su área de incumbencia."`;

export default function MissionSection() {
    const {width} = useWindowDimensions();
    const isMobile = width <= 768;
    return (
        <SpecialtiesHero
            title="Misión"
            height={isMobile ? 200 : 250}
            imageUrl={image}
            description={description}
            left={isMobile ? "unset" : "10px"}
            imageBackgroundColor="#E08D97"
            square
        />
    );
}
