import React, {useState} from 'react';
import './CreateLessonForm.css';
import {useForm} from "@mantine/form";
import {TextInput, Button, Grid, Select, MultiSelect} from "@mantine/core";
import axios from "axios";
import showNotification from "../../../utils/showNotification";

function CreateLessonForm({updateData, setUpdateData, setOpen, usersData}) {
    const [loading, setLoading] = useState(false);
    const form = useForm({
        initialValues: {
            name: "",
            state: "Open",
            initDate: "",
            modules: [],
            moderator: "",
            teachers: [],
            // materials: "",
            roomUrl: ""
        },
        validate: {},
    });
    const handleSubmit = async (values) => {
        console.log(values)
        setLoading(true)

        const data = {
            data: {...values}
        }
        axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/admin/lessons`, data)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setUpdateData(!updateData)
                    setOpen(false)
                } else {
                    showNotification({
                        color: "red",
                        status: "error",
                        title: "Error",
                        message: `Error al crear la especialidad`,
                    });
                }
            })
            .catch((err) => {
                console.log(err)
                const error = err.message || err.response.data.message || err || "Error al crear la especialidad";
                showNotification({
                    color: "red",
                    status: "error",
                    title: "Error",
                    message: `Error al crear la especialidad: ${error}`,
                });
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className="createLesson__container">
            <h2 className="createLesson__title">Crear clase</h2>
            <form
                onSubmit={form.onSubmit((values) => handleSubmit(values))}
                className="createLesson__form"
            >
                <Grid style={{width: "100%"}}>
                    <Grid.Col span={12}>
                        <TextInput
                            {...form.getInputProps('name')}
                            withAsterisk
                            label="Nombre de la clase"
                        />
                        <TextInput
                            placeholder="2024-02-28T00:30:00.000Z"
                            {...form.getInputProps('initDate')}
                            withAsterisk
                            label="Fecha de la clase"
                        />
                        <Select
                            {...form.getInputProps('moderator')}
                            withAsterisk
                            label="Moderador"
                            data={usersData}
                        />
                        <MultiSelect
                            {...form.getInputProps('teachers')}
                            withAsterisk
                            label="Proferores"
                            data={usersData}
                        />
                        <TextInput
                            {...form.getInputProps('roomUrl')}
                            withAsterisk
                            label="Link de la sala de zoom"
                        />
                    </Grid.Col>
                </Grid>
                <Button type="submit" className="createLesson__button" loading={loading}>Crear clase</Button>
            </form>
        </div>
    );
}

export default CreateLessonForm;