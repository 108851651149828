import './TeamContainer.css'
import { useEffect, useState } from 'react'
import TeamHeader from '../TeamHeader/TeamHeader'
import TeamBubbles from '../TeamBubbles/TeamBubbles'
import axios from 'axios'

export default function TeamContainer() {
   const [isLoading, setIsLoading] = useState(false)
   const [forVetTeam, setForVetTeam] = useState([])

   useEffect(() => {
      setIsLoading(true)
      const url = process.env.REACT_APP_BACKEND_SERVER + '/info/staff'
      axios.get(url)
         .then(response => setForVetTeam(response.data))
         .catch(err => console.log(err))
         .finally(() => setIsLoading(false))
   }, [])

   return (
      <section className='team-container'>
         <div className='team-first-container'>
            <TeamHeader />
            {forVetTeam?.directors?.length !== 0 && <div className='team-title-bubble-group'>
               <h3>DIRECTORES</h3>
               < TeamBubbles team={forVetTeam?.directors} isLoading={isLoading} />
            </div>}
            {forVetTeam?.staff?.length !== 0 && <div className='team-title-bubble-group'>
               <h3>STAFF FORVET</h3>
               <TeamBubbles
                  team={forVetTeam?.staff
                     ?.filter(item => item.forVetRole
                        && item.forVetRole.length > 0
                     )}
                  isLoading={isLoading}
               />
            </div>}
            {forVetTeam?.teachers?.length !== 0 && <div className='team-title-bubble-group'>
               <h3>PROFESORES</h3>
               <TeamBubbles team={forVetTeam?.teachers} isLoading={isLoading} hasToChange />
            </div>}
         </div>
      </section>
   )
}