import React, {useState} from 'react';
import './CreateDiscountForm.css';
import {useForm} from "@mantine/form";
import {TextInput, Button, Grid, Select, NumberInput} from "@mantine/core";
import axios from "axios";
import showNotification from "../../../utils/showNotification";
import {generateRandomCode} from "../../../utils/generateRandomCode";
import { IconCopy } from '@tabler/icons-react';

const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    showNotification({
        color: "green",
        status: "success",
        title: "Copiado",
        message: `Código copiado al portapapeles`,
        autoClose: 2000,
    });
}

function CreateDiscountForm({academicUnitsData, updateData, setUpdateData, setOpen}) {
    const [loading, setLoading] = useState(false);
    const form = useForm({
        initialValues: {
            academicUnit: "",
            code: "",
            percentage: 0,
            created_by: "ADMIN",
            active: true
        },
        validate: {},
    });
    const handleSubmit = async (values) => {
        setLoading(true)

        const data = {
            data: {...values}
        }

        axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/admin/discounts`, data)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setUpdateData(!updateData)
                    setOpen(false)
                } else {
                    showNotification({
                        color: "red",
                        status: "error",
                        title: "Error",
                        message: `Error al crear el descuento`,
                    });
                }
            })
            .catch((err) => {
                console.log(err)
                const error = err.message || err.response.data.message || err || "Error al crear el descuento";
                showNotification({
                    color: "red",
                    status: "error",
                    title: "Error",
                    message: `Error al crear el descuento: ${error}`,
                });
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className="createDiscount__container">
            <h2 className="createDiscount__title">Crear descuento</h2>
            <form
                onSubmit={form.onSubmit((values) => handleSubmit(values))}
                className="createDiscount__form"
            >
                <Grid style={{width: "100%"}}>
                    <Grid.Col span={12}>
                        <Select
                            {...form.getInputProps('academicUnit')}
                            withAsterisk
                            label="Unidad académica"
                            searchable={true}
                            data={academicUnitsData
                                .filter((academicUnit, index, self) => self.findIndex(t => t.name === academicUnit.name) === index).map(academicUnit => ({label: academicUnit.name, value: academicUnit.name}))}
                            onChange={(value) => {
                                const splitName = value.split(" ");
                                const academicUnitCode = splitName.map(_word => _word.charAt(0)).join("").toUpperCase();
                                const generatedCode = `DESC-${academicUnitCode}-${generateRandomCode(6)}`;
                                form.setFieldValue("code", generatedCode)
                                form.setFieldValue("academicUnit", value)
                            }}

                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <NumberInput
                            {...form.getInputProps('percentage')}
                            min={1}
                            max={100}
                            withAsterisk
                            label="Porcentaje de descuento"
                        />
                    </Grid.Col>
                    <Grid.Col span={10}>
                        <TextInput
                            {...form.getInputProps('code')}
                            withAsterisk
                            label="Código de descuento"
                            disabled
                        />
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <IconCopy className="createDiscount__icon" onClick={() => handleCopy(form.values.code)}/>
                    </Grid.Col>
                </Grid>
                <Button type="submit" className="createDiscount__button" loading={loading}>Crear descuento</Button>
            </form>
        </div>
    );
}

export default CreateDiscountForm;