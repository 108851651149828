import React, {useState} from 'react';
import './Admin.css';
import LoginForm from "../../features/Admin/LoginForm/LoginForm";
import CreateAcademicUnitForm from "../../features/Admin/CreateAcademicUnitForm/CreateAcademicUnitForm";
import {Navbar} from "../../features/Admin/Navbar/Navbar";
import DiscountsPanel from "../../features/Admin/DiscountsPanel/DiscountsPanel";


function Admin() {
    const [isAuth, setIsAuth] = useState(false);
    const [activeComponent, setActiveComponent] = useState('');
    const components = {
        academicUnitForm: <CreateAcademicUnitForm isAuth={isAuth} />,
        discountManager: <DiscountsPanel isAuth={isAuth} />,
    };

    return (
        <div className="admin__container">
            {isAuth &&
            <div className="admin__content">
                <Navbar setActiveComponent={setActiveComponent}/>
                <div className="admin__main">
                    <h2 className="admin__title">Panel de Administrador</h2>
                    {components[activeComponent] || null}
                </div>
            </div>
            }

            {!isAuth &&
            <LoginForm setIsAuth={setIsAuth}/>
            }
        </div>
    );
}


export default Admin;