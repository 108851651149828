import "./NewsCard.css";
import {Button} from "@mantine/core";
import {openUrl} from "../../../../utils/openUrl";
import useButtonSize from "../../../../hooks/useButtonSize";

const NewsCard = (data) => {
    const {title, route, image, description, buttonText, active} = data;
    const buttonSize = useButtonSize();
    return (
        <div className={`newsCard__wrapper newsCard__wrapper--${active}`} style={{background: `no-repeat center/100% url(${image})`, backgroundSize: 'cover'}}>
            <div className="newsCard__container">
                <h2 className="newsCard__title">{title}</h2>
                <p className="newsCard__text">{description}</p>

                <div className="newsCard__button">
                <Button variant="filled"
                        fw='normal'
                        size={buttonSize}
                        onClick={() => openUrl(route, false)}
                >
                    {buttonText}
                </Button>
                </div>
            </div>
        </div>
    );
};
export default NewsCard;
