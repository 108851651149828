import React, {useEffect, useRef, useState} from "react";
import "./SpecialtiesCarousel.css";
import "@mantine/carousel/styles.css";
import {Carousel} from "@mantine/carousel";
import Autoplay from 'embla-carousel-autoplay';
import SpecialtiesCard from "../SpecialtiesCard/SpecialtiesCard";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import axios from "axios";
import {Button} from "@mantine/core";
import {Link} from "react-router-dom";
import useButtonSize from "../../../../hooks/useButtonSize";

const SpecialtiesCarousel = () => {
    const [specialties, setSpecialties] = useState([]);
    const buttonSize = useButtonSize();
    const autoplay = useRef(Autoplay({ delay: 2000 })); 

    const fetchSpecialties = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_SERVER}/info/specialties`
            );
            const formatedResponse = response.data.map((specialty) => {
                return {
                    name: specialty.name,
                    image: specialty.photo,
                    route: `especializaciones/${specialty._id}`,
                };
            });
            setSpecialties(formatedResponse);
        } catch (error) {
            console.error("Error fetching specialties data:", error);
        }
    };

    useEffect(() => {
        fetchSpecialties();
    }, []);

    return (
        <>
            <div className="specialtiesCarousel-section-div">
                <SectionTitle
                    upText="Oferta Académica"
                    downText="Nuestras Especialidades"
                />
                <div className="specialtiesCarousel-father-div">
                    <Carousel
                        plugins={[autoplay.current]}
                        onMouseEnter={autoplay.current.stop}
                        onMouseLeave={autoplay.current.reset}
                        withControls
                        height={114}
                        slideSize={{base: "100%", sm: "50%", md: "25%"}}
                        slideGap={{base: 0, sm: "md"}}
                        loop={true}
                        align="start"
                    >
                        {specialties.map((specialty, index) => (
                            <Carousel.Slide key={index}>
                                <SpecialtiesCard
                                    name={specialty.name}
                                    image={specialty.image}
                                    route={specialty.route}
                                />
                            </Carousel.Slide>
                        ))}
                    </Carousel>
                    <div className="specialtiesCarousel-father-button">
                        <Link className="specialtiesCarousel-link-button" to="/cursos">
                            <Button
                                size={buttonSize}
                                className="specialtiesCarousel-button"
                                fw='normal'
                            >
                                Explorar Cursos
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SpecialtiesCarousel;
