import { Button, Group, Modal, Text } from '@mantine/core'
import React from 'react'

export default function TransferBankConfirmModal({ setModal, modal, isLoading, ...others }) {
   return (
      <Modal opened={modal} withCloseButton={false}
         onClose={() => setModal(false)}
         >
         <Group gap={"md"}>

            <Group justify="center">

               <div style={{ width: "90%", display: 'flex', flexDirection: 'column', gap: '20px' }}>
                  <Text ta={'center'} style={{ fontSize: 'var(--fs-h3)', lineHeight: '1.2' }}>¿Ya enviaste el comprobante?</Text>
                  <Text ta={'center'} style={{ fontSize: 'var(--fs-small)', lineHeight: 'var(--fs-small)' }}>
                     En caso de no haberlo realizado, es momento de hacerlo antes de continuar.
                  </Text>
               </div>

            </Group>

            <Group justify="center" w={'100%'} style={{ marginTop: '16px' }}>
               <Button variant="subtle" onClick={() => setModal(false)} disabled={isLoading}>
                  Aún no lo envié
               </Button>
               <Button color="secondaryColor" {...others} disabled={isLoading}>Ya lo envié</Button>
            </Group>
         </Group>
      </Modal>
   )
}
