import './IconCardText.css'

export default function IconCardText({icon, text, description, children, minHeight = 264}) {
  return (
      <article className='icon-card-text-container' style={{minHeight: `${minHeight}px`}}>
         {children}
         {icon && < icon />}
         <h4 className='icon-card-text-title'>{text}</h4>
         <p className='icon-card-text-paragraph'>{description}</p>
      </article>
  )
}
