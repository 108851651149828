import CoursesFilter from "../../features/CoursesPage/CoursesFilter/CoursesFilter";
import CoursesHero from "../../features/CoursesPage/CoursesHero/CoursesHero";
import CoursesOffers from "../../features/CoursesPage/CoursesOffers/CoursesOffers";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import "./CoursesPage.css";
import {useEffect, useState} from "react";
import axios from "axios";
import {useIsMount} from "../../hooks/useIsMount";
import DecorativeCircle from "../../components/DecorativeCircle/DecorativeCircle";
import TinyDecorativeCircle from "../../components/TinyDecorativeCircle/TinyDecorativeCircle";
import {useLocation} from "react-router";
import CardInfoSkeleton from "../../components/CardInfoSkeleton/CardInfoSkeleton";
import {errorHandler} from "../../utils/errorHandler";
import moment from "moment";
import "moment/locale/es";

const PAGE_SIZE = 12;
const MONTHS_DICT = {
    'enero': 0,
    'febrero': 1,
    'marzo': 2,
    'abril': 3,
    'mayo': 4,
    'junio': 5,
    'julio': 6,
    'agosto': 7,
    'septiembre': 8,
    'octubre': 9,
    'noviembre': 10,
    'diciembre': 11
};

const CoursesPage = () => {
    const [specialtyFilter, setSpecialtyFilter] = useState(null);
    const [academicUnitFilter, setAcademicUnitFilter] = useState(null);
    const [monthFilter, setMonthFilter] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null)
    const [allCourses, setAllCourses] = useState([]);
    const [activePage, setPage] = useState(1);
    const isMount = useIsMount();
    const location = useLocation();

    const filterCourses = (_courses) => {
        if (!_courses) return
        //APPLY FILTERS
        let filteredCourses = _courses;
        if (specialtyFilter) {
            filteredCourses = filteredCourses.filter((course) => course.specialties.toUpperCase() === specialtyFilter);
        }
        if (academicUnitFilter) {
            filteredCourses = filteredCourses.filter((course) => course.type.toUpperCase() === academicUnitFilter);
        }
        if (monthFilter) {
            filteredCourses = filteredCourses.filter((course) => {
                return moment(course.startDate).locale("es").format("MMMM").toUpperCase() === monthFilter;
            });
        }
        return filteredCourses;
    }

    const paginatedCourses = (_filteredCourses, _page, _pageSize) => {
        if (!_filteredCourses) return
        //APPLY PAGINATION
        const startIndex = (_page - 1) * _pageSize;
        const endIndex = startIndex + _pageSize;

        return _filteredCourses.slice(startIndex, endIndex);
    };

    useEffect(() => {
        if (isMount) {
            setIsLoading(true);
            const url = process.env.REACT_APP_BACKEND_SERVER + `/info/academicUnits`;
            axios
                .get(url)
                .then((res) => {
                    // Sort courses by date
                    const openedCourses = res.data.open.map((_oCourse) => {
                        // Filter courses outsideCampus (openSource webinars)
                        if (_oCourse.comercialInformation.outsideCampus) return null;
                        const dateParts = _oCourse.comercialInformation.initialDate.split(" ")
                        const datePartsLower = dateParts.map(el => el.toLowerCase());
                        if (datePartsLower.includes("de")) {
                            datePartsLower.splice(datePartsLower.indexOf("de"), 1);
                        }

                        const month = MONTHS_DICT[datePartsLower[1].toLowerCase()];
                        const date = moment({
                            day: datePartsLower[0],
                            month: month,
                            year: datePartsLower[2]
                        });

                        return {
                            ..._oCourse,
                            startDate: date
                        }
                    }).filter(el => el !== null)
                    openedCourses.sort((a, b) => b.startDate - a.startDate);
                    const closedCourses = res.data.close.map((_cCourse) => {
                        // Filter courses outsideCampus (openSource webinars)
                        if (_cCourse.comercialInformation.outsideCampus) return null;
                        const dateParts = _cCourse.comercialInformation.initialDate.split(" ")
                        const datePartsLower = dateParts.map(el => el.toLowerCase());
                        if (datePartsLower.includes("de")) {
                            datePartsLower.splice(datePartsLower.indexOf("de"), 1);
                        }

                        const month = MONTHS_DICT[datePartsLower[1].toLowerCase()];
                        const date = moment({
                            day: datePartsLower[0],
                            month: month,
                            year: datePartsLower[2]
                        });

                        return {
                            ..._cCourse,
                            startDate: date
                        }
                    }).filter(el => el !== null)
                    closedCourses.sort((a, b) => b.startDate - a.startDate);

                    // Calculate upcoming courses
                    const currentDate = moment();
                    const upcomingCourses = openedCourses.filter(course => course.startDate.isAfter(currentDate));

                    setAllCourses({
                        open: openedCourses,
                        close: closedCourses,
                        upcomingCourses: upcomingCourses.slice(0, 3)
                    });
                })
                .catch((err) => {
                    console.log(err)
                    const errorMessage = errorHandler(err)
                    setError(errorMessage)
                })
                .finally(() => setIsLoading(false));
        }
    }, [isMount]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tipoParam = searchParams.get("tipo");

        if (tipoParam) {
            setAcademicUnitFilter(tipoParam.toUpperCase());
        }
    }, [location.search]);

    return (
        <MainLayout overflow="hidden">
            {/*< UserLocationModal />*/}
            <section style={{position: "relative"}}
                     className="coursesPage__section--noPadding coursesPage-section-position-custom-coursesHero">
                <CoursesHero/>
                <DecorativeCircle size={100} top={-20} left={-20} opacity={0.25} shadow={1}/>
                <DecorativeCircle size={150} bottom={-100} left={300} opacity={0.2} shadow={1}/>
                <DecorativeCircle size={200} bottom={-200} right={-200} opacity={0.2} shadow={2}/>
                <TinyDecorativeCircle size={12} top={50} right={100} opacity={1} color={1}/>
                <TinyDecorativeCircle size={12} top={300} left={70} opacity={1} color={1}/>
                <TinyDecorativeCircle size={32} top={100} left={900} opacity={1} color={3}/>
                <TinyDecorativeCircle size={48} top={300} left={750} opacity={1} color={3}/>
            </section>

            <section className="coursesPage__section coursesPage-section-position-custom-coursesFilter">
                <CoursesFilter
                    specialtyFilter={specialtyFilter}
                    setSpecialtyFilter={setSpecialtyFilter}
                    academicUnitFilter={academicUnitFilter}
                    setAcademicUnitFilter={setAcademicUnitFilter}
                    monthFilter={monthFilter}
                    setMonthFilter={setMonthFilter}
                />
            </section>

            <section className="coursesPage__section">
                {(error && !isLoading) &&
                <p style={{fontSize: 'var(--fs-body)', textAlign: 'center'}}>
                    {error}
                </p>
                }

                {(isLoading && !error) &&
                <div className="course-offers-container">
                    <CardInfoSkeleton height={200}/>
                </div>
                }

                {(!isLoading && !error && allCourses.open) &&
                <CoursesOffers
                    data={paginatedCourses(filterCourses(allCourses.open), activePage, PAGE_SIZE)}
                    upcomingCourses={allCourses.upcomingCourses}
                    displayUpcomingCourses={!monthFilter && !academicUnitFilter && !specialtyFilter}
                    isLoading={isLoading}
                    activePage={activePage}
                    setPage={setPage}
                    totalPages={Math.ceil(filterCourses(allCourses.open).length / PAGE_SIZE)}
                    finishedCourses={filterCourses(allCourses.close)}
                />
                }
            </section>
        </MainLayout>
    );
};
export default CoursesPage;
