import useWindowDimensions from "./useWindowDimensions";

const useButtonSize = () => {
    const { width } = useWindowDimensions();
    const isMediumScreen = width <= 1200;
    const isMobile = width <= 768;

    const getButtonSize = () => {
        if (isMobile) {
            return "sm";
        } else if (isMediumScreen) {
            return "md";
        } else {
            return "lg";
        }
    };

    return getButtonSize();
};

export default useButtonSize;