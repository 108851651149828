import { Skeleton } from "@mantine/core";

export default function TeamBubblesSkeleton() {
   return (
      <div className='teamBubbles-container team-bubbles-skeleton' style={{gap: '5px'}}>
         < Skeleton circle height={60} width={60} />
         < Skeleton circle height={80} width={80} />
         < Skeleton circle height={60} width={60} />

      </div>
   )
}
