import React, {useEffect, useRef, useState} from 'react';
import "./DiscountsPanel.css";
import {useNavigate} from "react-router";
import axios from "axios";
import {Button, Loader, Modal, TextInput} from "@mantine/core";
import {
    useReactTable, createColumnHelper, getCoreRowModel, getSortedRowModel, flexRender,
    getPaginationRowModel, getFilteredRowModel
} from '@tanstack/react-table'
import {IconCircleArrowDown, IconCircleArrowUp} from "@tabler/icons-react";
import CreateDiscountForm from "../CreateDiscountForm/CreateDiscountForm";

const paginationComponent = (table) => {
    return (<div className="discountsPanel__pagination__container">
        <div className="discountsPanel__paginationItems__container">
            <span className="discountsPanel__paginationItems__label">Items por página</span>
            <select
                className="discountsPanel__paginationItems__select"
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                }}
            >
                {[10, 20, 30].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                        {pageSize}
                    </option>
                ))}
            </select>
        </div>
        <div className="discountsPanel__paginationButtons__container">
            <button
                className={`discountsPanel__paginationButtons__button ${
                    !table.getCanPreviousPage() ? 'discountsPanel__paginationButtons__button--disabled' : ''
                }`}
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
            >
                <span>{'<<'}</span>
            </button>
            <button
                className={`discountsPanel__paginationButtons__button ${
                    !table.getCanPreviousPage() ? 'discountsPanel__paginationButtons__button--disabled' : ''
                }`}
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
            >
                <span>{'<'}</span>
            </button>
            <span className="discountsPanel__pagination__text">
                        <input
                            min={1}
                            max={table.getPageCount()}
                            type="number"

                            value={table.getState().pagination.pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                table.setPageIndex(page);
                            }}
                            className="discountsPanel__pagination__input"
                        />
                        de {table.getPageCount()}
                    </span>
            <button
                className={`discountsPanel__paginationButtons__button ${
                    !table.getCanNextPage() ? 'discountsPanel__paginationButtons__button--disabled' : ''
                }`}
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
            >
                <span>{'>'}</span>
            </button>
            <button
                className={`discountsPanel__paginationButtons__button ${
                    !table.getCanNextPage() ? 'disabled' : ''
                }`}
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
            >
                <span className="button-text">{'>>'}</span>
            </button>
        </div>
    </div>)
}


function DiscountsPanel(props) {
    const {isAuth} = props;
    const navigate = useNavigate();
    const [discountsData, setDiscountsData] = useState(null);
    const [academicUnitsData, setAcademicUnitsData] = useState(null);
    const skipPageResetRef = useRef();
    const columnHelper = createColumnHelper();
    const [sorting, setSorting] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [createDiscountModal, setCreateDiscountModal] = useState(false);
    const [updateData, setUpdateData] = useState(false);

    const table = useReactTable({
        autoResetPageIndex: !skipPageResetRef.current,
        autoResetExpanded: !skipPageResetRef.current,
        columns: [
            columnHelper.accessor('code', {
                cell: info => info.getValue(),
                header: 'Código',
                enableColumnFilter: true,
                enableSorting: false,
                filter: 'text',
            }),
            columnHelper.accessor('academicUnitName', {
                cell: info => info.getValue(),
                header: 'Unidad Académica',
                enableColumnFilter: true,
                enableSorting: false,
                filter: 'text',
            }),
            columnHelper.accessor('institution', {
                cell: info => info.getValue(),
                header: 'Institución',
                enableColumnFilter: true,
                enableSorting: false,
                filter: 'text',
            }),
            columnHelper.accessor('percentage', {
                cell: info => info.getValue(),
                header: 'Porcentaje',
                enableColumnFilter: false,
                enableSorting: true,
            }),
            columnHelper.accessor('active', {
                cell: info => info.getValue() ? "Sí" : "No",
                header: 'Activo',
                enableColumnFilter: false,
                enableSorting: true,
            }),
        ],
        getCoreRowModel: getCoreRowModel(),
        state: {
            sorting,
            columnFilters,
        },
        initialState: {
            pagination: {
                pageSize: 10,
            },
        },
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        data: discountsData
    })


    const init = async () => {
        // Academic Units
        const academicUnitsInfo = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER}/admin/academicunits`)
        const academicUnitsData = academicUnitsInfo.data.open.map((unit) => {
            const splitName = unit.comercialInformation.name.split(" ");
            const academicUnitCode = splitName.map(_word => _word.charAt(0)).join("").toUpperCase();

            return {
                id: unit._id,
                name: unit.comercialInformation.name,
                code: academicUnitCode,
            }
        })
        setAcademicUnitsData(academicUnitsData)

        // Discounts
        const discountsInfo = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER}/admin/discounts`)
        const discountData = discountsInfo.data.map((discount) => {
            const academicUnitData = academicUnitsData.find(unit => unit.code === discount.code.split("-")[1])
            return {
                id: discount._id,
                code: discount.code,
                percentage: discount.percentage,
                active: discount.active,
                institution: discount.code.split("-")[0],
                academicUnitName: academicUnitData?.name || "",
                academicUnitId: academicUnitData?.id || "",

            }
        })
        skipPageResetRef.current = true
        setDiscountsData(discountData)
    }

    useEffect(() => {
        if (isAuth) {
            init()
        } else {
            navigate("/admin")
        }
    }, [isAuth, updateData]);

    useEffect(() => {
        skipPageResetRef.current = false
    }, []);

    if (!discountsData) return <Loader/>;
    return (
        <div className="discountsPanel__container">
            <Button
                className="discountsPanel__button"
                onClick={() => setCreateDiscountModal(true)}
            >
                Crear descuento
            </Button>
            <table className="discountsPanel__table">
                <thead className="discountsPanel__head">
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                {header.column.getCanFilter() && (
                                    <TextInput
                                        placeholder="Filtrar"
                                        value={header.column.getFilterValue() || ''}
                                        onChange={(e) => {
                                            header.column.setFilterValue(e.target.value)
                                        }}
                                    />
                                )}
                                {header.column.getCanSort() && (
                                    <div className="discountsPanel__sort">
                                        <IconCircleArrowDown
                                            className="discountsPanel__sort__icon"
                                            onClick={() => header.column.toggleSorting(true)}/>
                                        <IconCircleArrowUp
                                            className="discountsPanel__sort__icon"
                                            onClick={() => header.column.toggleSorting(false)}/>
                                    </div>
                                )}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody className="discountsPanel__body">
                {table.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <td key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
                <tfoot>
                {table.getFooterGroups().map(footerGroup => (
                    <tr key={footerGroup.id}>
                        {footerGroup.headers.map(header => (
                            <th key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.footer,
                                        header.getContext()
                                    )}
                            </th>
                        ))}
                    </tr>
                ))}
                </tfoot>
            </table>
            {paginationComponent(table)}
            <Modal opened={createDiscountModal} onClose={() => setCreateDiscountModal(false)}>
                <CreateDiscountForm
                    academicUnitsData={academicUnitsData}
                    updateData={updateData}
                    setUpdateData={setUpdateData}
                    setOpen={setCreateDiscountModal}
                />
            </Modal>
        </div>
    );
}

export default DiscountsPanel;